import React from "react";
import { useToken } from "../session";
import { useQuery, gql } from "@apollo/client";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import { Segment, Dimmer, Loader, Statistic, Message } from "semantic-ui-react";
import { PageContent } from "../components/Layout";

const GET_EVENT_TYPES = gql`
  {
    customer {
      questionnaires {
        id
      }
      eventTypes {
        id
      }
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  gap: 1em;
  @media (min-width: 720px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledSegment = styled(Segment)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
`;

export function CustomStatistic({ label, value, defaultValue = 0 }) {
  return (
    <StyledSegment>
      <Statistic>
        <Statistic.Value>{value || defaultValue}</Statistic.Value>
        <Statistic.Label>{label}</Statistic.Label>
      </Statistic>
    </StyledSegment>
  );
}

export default function () {
  const { loading, error, data } = useQuery(GET_EVENT_TYPES);

  return <Redirect to={"/eventTypes"} />;

  return (
    <PageContent heading="Dashboard">
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>

      {error && <Message negative>{error.message}</Message>}

      <Grid>
        <CustomStatistic
          label="Veranstaltungen"
          value={data && data.customer.eventTypes.length}
        />
        <CustomStatistic
          label="Fragebögen"
          value={data && data.customer.questionnaires.length}
        />
      </Grid>
    </PageContent>
  );
}
