import React from "react";
import { useToken } from "../session";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { Table } from "../components/Table";
import { PageContent } from "../components/Layout";
import { EventType, useGetEventTypesQuery } from "../generated/graphql";
import { Button, Icon, Message } from "semantic-ui-react";
import { Column } from "react-table";
import { createLumdataltest, createQuestionnaire } from "../lumdatal";
import "../lumdatal-kelkheim";

export default function EventTypeList() {
  const { loading, error, data } = useGetEventTypesQuery();
  console.log({ createLumdataltest, createQuestionnaire });
  const columns = React.useMemo(
    (): Column<EventType>[] => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Fragebogen",
        accessor: "questionnaire",
        Cell: ({ cell: { value } }) => (
          <Link to={`/questionnaires/${value.id}`}>{value.name}</Link>
        ),
      },
      {
        Header: "Aktionen",
        Cell: ({
          cell: {
            row: {
              // @ts-ignore
              original: { id },
            },
          },
        }) => (
          <div>
            <Button
              as={Link}
              to={`/eventTypes/${id}`}
              icon
              labelPosition="left"
            >
              <Icon name="eye" />
              Details
            </Button>
            <Button
              as={Link}
              to={`/eventTypes/${id}/evaluation`}
              icon
              labelPosition="left"
            >
              <Icon name="area graph" />
              Auswertung
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <PageContent heading={`Veranstaltungstypen`}>
      <div>
        {error && <Message negative>{error.message}</Message>}

        <Table
          columns={columns}
          data={data?.eventTypes ?? []}
          renderRowSubComponent={() => null}
        />
        {data?.eventTypes?.length === 0 && (
          <p>Sie haben noch keine Veranstaltungstypen angelgt.</p>
        )}

        <Button
          as={Link}
          to={"/eventTypes/new"}
          positive
          icon
          labelPosition="left"
        >
          <Icon name="plus" />
          Neuen Veranstaltungstyp erstellen
        </Button>
      </div>
    </PageContent>
  );
}
