import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: any;
};

export type AccessCode = {
  __typename?: 'AccessCode';
  id: Scalars['ID'];
  tan: Scalars['String'];
  session?: Maybe<AnswerSession>;
  raffleTicketIssued: Scalars['Boolean'];
  batch: AccessCodeBatch;
};

export type AccessCodeBatch = {
  __typename?: 'AccessCodeBatch';
  id: Scalars['ID'];
  totalCodes: Scalars['Int'];
  codesUsed: Scalars['Int'];
  codesClosed: Scalars['Int'];
  accessCodes: Array<AccessCode>;
  event: Event;
  createdAt: Scalars['DateTime'];
};

export type AllEventMetadataItem = {
  __typename?: 'AllEventMetadataItem';
  label: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['ID'];
  question: Question;
  value?: Maybe<Scalars['String']>;
  session: AnswerSession;
};

export type AnswerSession = {
  __typename?: 'AnswerSession';
  id: Scalars['ID'];
  accessCode: AccessCode;
  token: Scalars['String'];
  answers: Array<Answer>;
  comment?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['DateTime']>;
  event: Event;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  users: Array<User>;
  eventTypes: Array<EventType>;
  questionTypes: Array<QuestionType>;
  questionnaires: Array<Questionnaire>;
};


export type Evaluation = {
  __typename?: 'Evaluation';
  questions: Array<QuestionEvaluation>;
  startedSessions: Scalars['Int'];
  endedSessions: Scalars['Int'];
  totalAnswers: Scalars['Int'];
};

export type EvaluationFilter = {
  answers?: Maybe<Array<EvaluationFilterQuestionAnswered>>;
};

export type EvaluationFilterQuestionAnswered = {
  question: Scalars['ID'];
  answer?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  name: Scalars['String'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  eventType: EventType;
  eventGroup: EventGroup;
  metadata: Array<EventMetadataItem>;
  createdAt: Scalars['DateTime'];
  accessCodeBatches: Array<AccessCodeBatch>;
  raffle?: Maybe<Raffle>;
  answerSessions: Array<AnswerSession>;
  statistics: EventStatistics;
  tan: Scalars['String'];
};

export type EventGroup = {
  __typename?: 'EventGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  events: Array<Event>;
  eventType: EventType;
};

export type EventGroupTemplate = {
  __typename?: 'EventGroupTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  templates: Array<EventGroupTemplateEventTemplate>;
};

export type EventGroupTemplateEventTemplate = {
  __typename?: 'EventGroupTemplateEventTemplate';
  name: Scalars['String'];
  metadata: Array<EventMetadataItem>;
  day: Scalars['Int'];
  from: Scalars['String'];
  to: Scalars['String'];
};

export type EventMetadataItem = {
  __typename?: 'EventMetadataItem';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type EventMetadataItemInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type EventStatistics = {
  __typename?: 'EventStatistics';
  sessions: Scalars['Int'];
  sessionsClosed: Scalars['Int'];
};

export type EventType = {
  __typename?: 'EventType';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  eventGroups: Array<EventGroup>;
  events: Array<Event>;
  questionnaire: Questionnaire;
  availableMetadata: Array<AllEventMetadataItem>;
};



export type LoginResult = {
  __typename?: 'LoginResult';
  error?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createEventType: EventType;
  updateEventType?: Maybe<Scalars['Boolean']>;
  deleteEventType?: Maybe<Scalars['Boolean']>;
  createEventGroupTemplate: EventGroupTemplate;
  updateEventGroupTemplate?: Maybe<Scalars['Boolean']>;
  deleteEventGroupTemplate?: Maybe<Scalars['Boolean']>;
  createEventGroup: EventGroup;
  updateEventGroup?: Maybe<Scalars['Boolean']>;
  deleteEventGroup?: Maybe<Scalars['Boolean']>;
  createEvent: Event;
  updateEvent?: Maybe<Event>;
  deleteEvent?: Maybe<Scalars['Boolean']>;
  generateAccessCodeBatch: AccessCodeBatch;
  startAnswerSessionWithAccessCode: AnswerSession;
  storeAnswer?: Maybe<Scalars['Boolean']>;
  storeComment?: Maybe<Scalars['Boolean']>;
  endSession?: Maybe<Scalars['Boolean']>;
  enterRaffle?: Maybe<Scalars['String']>;
  updateQuestionType?: Maybe<Scalars['Boolean']>;
  createQuestionType: QuestionType;
  deleteQuestionType?: Maybe<Scalars['Boolean']>;
  createQuestion: Question;
  updateQuestion?: Maybe<Scalars['Boolean']>;
  createQuestionnaire: Questionnaire;
  updateQuestionnaire?: Maybe<Scalars['Boolean']>;
  deleteQuestionnaire: Scalars['Boolean'];
  changePassword?: Maybe<Scalars['Boolean']>;
  login: LoginResult;
};


export type MutationCreateEventTypeArgs = {
  name: Scalars['String'];
  questionnaire: Scalars['ID'];
};


export type MutationUpdateEventTypeArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteEventTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventGroupTemplateArgs = {
  name: Scalars['String'];
};


export type MutationUpdateEventGroupTemplateArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  events: Array<Scalars['String']>;
};


export type MutationDeleteEventGroupTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventGroupArgs = {
  eventTypeId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateEventGroupArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteEventGroupArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  name: Scalars['String'];
  eventGroupId: Scalars['ID'];
  metadata: Array<EventMetadataItemInput>;
};


export type MutationUpdateEventArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  metadata: Array<EventMetadataItemInput>;
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateAccessCodeBatchArgs = {
  eventId: Scalars['ID'];
  amount: Scalars['Int'];
};


export type MutationStartAnswerSessionWithAccessCodeArgs = {
  tan: Scalars['String'];
};


export type MutationStoreAnswerArgs = {
  token: Scalars['String'];
  questionId: Scalars['ID'];
  answer: Scalars['String'];
};


export type MutationStoreCommentArgs = {
  token: Scalars['String'];
  comment: Scalars['String'];
};


export type MutationEndSessionArgs = {
  token: Scalars['String'];
};


export type MutationEnterRaffleArgs = {
  token: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateQuestionTypeArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  style: QuestionStyle;
  options: Scalars['JSONObject'];
};


export type MutationCreateQuestionTypeArgs = {
  name: Scalars['String'];
  style: QuestionStyle;
  options: Scalars['JSONObject'];
};


export type MutationDeleteQuestionTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateQuestionArgs = {
  text: Scalars['String'];
  typeId: Scalars['ID'];
  required: Scalars['Boolean'];
  nextQuestionId?: Maybe<Scalars['ID']>;
  nextCondition?: Maybe<Scalars['String']>;
};


export type MutationUpdateQuestionArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  type: Scalars['ID'];
  required: Scalars['Boolean'];
  nextQuestionId?: Maybe<Scalars['ID']>;
  nextCondition?: Maybe<Scalars['String']>;
};


export type MutationCreateQuestionnaireArgs = {
  name: Scalars['String'];
  questions: Array<Scalars['ID']>;
};


export type MutationUpdateQuestionnaireArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<Scalars['ID']>;
};


export type MutationDeleteQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  userId?: Maybe<Scalars['ID']>;
  password: Scalars['String'];
};


export type MutationLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  me: User;
  customers: Array<Customer>;
  customer?: Maybe<Customer>;
  eventTypes?: Maybe<Array<EventType>>;
  eventType?: Maybe<EventType>;
  eventGroupTemplates: Array<EventGroupTemplate>;
  eventGroupTemplate?: Maybe<EventGroupTemplate>;
  eventGroup?: Maybe<EventGroup>;
  events?: Maybe<Array<Event>>;
  event?: Maybe<Event>;
  raffles?: Maybe<Array<Raffle>>;
  session?: Maybe<AnswerSession>;
  questionTypes?: Maybe<Array<QuestionType>>;
  questionType?: Maybe<QuestionType>;
  questionnaires?: Maybe<Array<Questionnaire>>;
  questionnaire?: Maybe<Questionnaire>;
  evaluation: Evaluation;
};


export type QueryCustomerArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryEventTypesArgs = {
  customerId?: Maybe<Scalars['ID']>;
};


export type QueryEventTypeArgs = {
  id: Scalars['ID'];
};


export type QueryEventGroupTemplatesArgs = {
  customerId?: Maybe<Scalars['ID']>;
};


export type QueryEventGroupTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryEventGroupArgs = {
  id: Scalars['ID'];
};


export type QueryEventsArgs = {
  eventGroupId: Scalars['ID'];
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryRafflesArgs = {
  customerId?: Maybe<Scalars['ID']>;
};


export type QuerySessionArgs = {
  token: Scalars['String'];
};


export type QueryQuestionTypeArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type QueryEvaluationArgs = {
  eventType: Scalars['ID'];
  eventsToInclude: Array<Scalars['ID']>;
  filter?: Maybe<EvaluationFilter>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  text: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: QuestionType;
  nextCondition?: Maybe<QuestionNextCondition>;
};

export type QuestionEvaluation = {
  __typename?: 'QuestionEvaluation';
  question: Question;
  results: Array<QuestionResult>;
  plotly: Scalars['JSON'];
};

export type QuestionNextCondition = {
  __typename?: 'QuestionNextCondition';
  nextQuestion: Scalars['ID'];
  condition: Scalars['String'];
};

export type QuestionResult = {
  __typename?: 'QuestionResult';
  answer: Scalars['String'];
  absolute: Scalars['Int'];
};

export enum QuestionStyle {
  Shorttext = 'SHORTTEXT',
  Longtext = 'LONGTEXT',
  Dropdown = 'DROPDOWN',
  Radio = 'RADIO',
  Int = 'INT',
  Float = 'FLOAT',
  Slider = 'SLIDER'
}

export type QuestionType = {
  __typename?: 'QuestionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  style: QuestionStyle;
  options: Scalars['JSONObject'];
  isInUse: Scalars['Boolean'];
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  id: Scalars['ID'];
  official: Scalars['Boolean'];
  name: Scalars['String'];
  questions: Array<Question>;
  eventTypes: Array<EventType>;
};

export type Raffle = {
  __typename?: 'Raffle';
  id: Scalars['ID'];
  prices: Array<RafflePrice>;
  winnersDetermined: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  participants: Scalars['Int'];
};

export type RafflePrice = {
  __typename?: 'RafflePrice';
  id: Scalars['ID'];
  name: Scalars['String'];
  winningTicket?: Maybe<RaffleTicket>;
  raffle: Raffle;
};

export type RaffleTicket = {
  __typename?: 'RaffleTicket';
  id: Scalars['ID'];
  email: Scalars['String'];
  createdAt: Scalars['DateTime'];
  raffle: Raffle;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  username: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
};

export type AccessCodeBatchFragment = { __typename?: 'AccessCodeBatch', id: string, totalCodes: number, codesUsed: number, codesClosed: number, createdAt: any };

export type UserWithCustomerFragment = { __typename?: 'User', id: string, username: string, createdAt: any, customer: { __typename?: 'Customer', id: string, name: string, email?: Maybe<string>, image: string, phone?: Maybe<string>, createdAt: any } };

export type CustomerFragment = { __typename?: 'Customer', id: string, name: string, email?: Maybe<string>, image: string, phone?: Maybe<string>, createdAt: any };

export type QuestionnaireMetaFragment = { __typename?: 'Questionnaire', id: string, name: string, official: boolean };

export type FullQuestionnaireFragment = { __typename?: 'Questionnaire', id: string, name: string, official: boolean, questions: Array<{ __typename?: 'Question', id: string, text: string, required?: Maybe<boolean>, type: { __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean }, nextCondition?: Maybe<{ __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string }> }>, eventTypes: Array<{ __typename?: 'EventType', id: string, name: string, createdAt: any, availableMetadata: Array<{ __typename?: 'AllEventMetadataItem', label: string, values: Array<string> }> }> };

export type EventTypeMetaFragment = { __typename?: 'EventType', id: string, name: string, createdAt: any, availableMetadata: Array<{ __typename?: 'AllEventMetadataItem', label: string, values: Array<string> }> };

export type AllEventMetadataItemFragment = { __typename?: 'AllEventMetadataItem', label: string, values: Array<string> };

export type QuestionFragment = { __typename?: 'Question', id: string, text: string, required?: Maybe<boolean>, type: { __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean }, nextCondition?: Maybe<{ __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string }> };

export type QuestionTypeFragment = { __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResult', error?: Maybe<string>, token?: Maybe<string>, user?: Maybe<{ __typename?: 'User', id: string, username: string, createdAt: any, customer: { __typename?: 'Customer', id: string, name: string } }> } };

export type CreateQuestionnaireMutationVariables = Exact<{
  name: Scalars['String'];
  questions: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type CreateQuestionnaireMutation = { __typename?: 'Mutation', createQuestionnaire: { __typename?: 'Questionnaire', id: string, name: string, official: boolean, questions: Array<{ __typename?: 'Question', id: string, text: string, required?: Maybe<boolean>, type: { __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean }, nextCondition?: Maybe<{ __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string }> }>, eventTypes: Array<{ __typename?: 'EventType', id: string, name: string, createdAt: any, availableMetadata: Array<{ __typename?: 'AllEventMetadataItem', label: string, values: Array<string> }> }> } };

export type UpdateQuestionnaireMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateQuestionnaireMutation = { __typename?: 'Mutation', updateQuestionnaire?: Maybe<boolean> };

export type CreateQuestionMutationVariables = Exact<{
  text: Scalars['String'];
  typeId: Scalars['ID'];
  required: Scalars['Boolean'];
}>;


export type CreateQuestionMutation = { __typename?: 'Mutation', createQuestion: { __typename?: 'Question', id: string, text: string, required?: Maybe<boolean>, type: { __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean }, nextCondition?: Maybe<{ __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string }> } };

export type UpdateQuestionMutationVariables = Exact<{
  id: Scalars['ID'];
  text: Scalars['String'];
  typeId: Scalars['ID'];
  required: Scalars['Boolean'];
  nextQuestionId?: Maybe<Scalars['ID']>;
  nextCondition?: Maybe<Scalars['String']>;
}>;


export type UpdateQuestionMutation = { __typename?: 'Mutation', updateQuestion?: Maybe<boolean> };

export type UpdateQuestionTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  style: QuestionStyle;
  options: Scalars['JSONObject'];
}>;


export type UpdateQuestionTypeMutation = { __typename?: 'Mutation', updateQuestionType?: Maybe<boolean> };

export type CreateQuestionTypeMutationVariables = Exact<{
  name: Scalars['String'];
  style: QuestionStyle;
  options: Scalars['JSONObject'];
}>;


export type CreateQuestionTypeMutation = { __typename?: 'Mutation', createQuestionType: { __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean } };

export type DeleteQuestionTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteQuestionTypeMutation = { __typename?: 'Mutation', deleteQuestionType?: Maybe<boolean> };

export type GenerateBatchMutationVariables = Exact<{
  eventId: Scalars['ID'];
  amount: Scalars['Int'];
}>;


export type GenerateBatchMutation = { __typename?: 'Mutation', generated: { __typename?: 'AccessCodeBatch', id: string, totalCodes: number, createdAt: any, accessCodes: Array<{ __typename?: 'AccessCode', tan: string }> } };

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  metadata: Array<EventMetadataItemInput> | EventMetadataItemInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent?: Maybe<{ __typename?: 'Event', id: string, name: string, metadata: Array<{ __typename?: 'EventMetadataItem', label: string, value: string }> }> };

export type CreateEventTypeMutationVariables = Exact<{
  name: Scalars['String'];
  questionnaire: Scalars['ID'];
}>;


export type CreateEventTypeMutation = { __typename?: 'Mutation', createEventType: { __typename?: 'EventType', id: string, name: string, createdAt: any, questionnaire: { __typename?: 'Questionnaire', id: string, name: string, official: boolean }, events: Array<{ __typename?: 'Event', id: string }>, availableMetadata: Array<{ __typename?: 'AllEventMetadataItem', label: string, values: Array<string> }> } };

export type CreateEventGroupMutationVariables = Exact<{
  name: Scalars['String'];
  eventTypeId: Scalars['ID'];
}>;


export type CreateEventGroupMutation = { __typename?: 'Mutation', createEventGroup: { __typename?: 'EventGroup', id: string } };

export type CreateEventMutationVariables = Exact<{
  name: Scalars['String'];
  eventGroupId: Scalars['ID'];
  metadata: Array<EventMetadataItemInput> | EventMetadataItemInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: string, name: string, statistics: { __typename?: 'EventStatistics', sessions: number, sessionsClosed: number } } };

export type GetEventDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEventDetailsQuery = { __typename?: 'Query', event?: Maybe<{ __typename?: 'Event', id: string, name: string, tan: string, metadata: Array<{ __typename?: 'EventMetadataItem', label: string, value: string }>, accessCodeBatches: Array<{ __typename?: 'AccessCodeBatch', id: string, totalCodes: number, codesUsed: number, codesClosed: number, createdAt: any }>, eventGroup: { __typename?: 'EventGroup', id: string, name: string }, eventType: { __typename?: 'EventType', id: string, name: string, questionnaire: { __typename?: 'Questionnaire', id: string, name: string }, availableMetadata: Array<{ __typename?: 'AllEventMetadataItem', label: string, values: Array<string> }> } }> };

export type GetEventTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventTypesQuery = { __typename?: 'Query', eventTypes?: Maybe<Array<{ __typename?: 'EventType', id: string, name: string, questionnaire: { __typename?: 'Questionnaire', id: string, name: string, official: boolean }, events: Array<{ __typename?: 'Event', id: string, name: string }> }>> };

export type GetEventTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetEventTypeQuery = { __typename?: 'Query', eventType?: Maybe<{ __typename?: 'EventType', id: string, name: string, createdAt: any, questionnaire: { __typename?: 'Questionnaire', id: string, name: string, official: boolean }, eventGroups: Array<{ __typename?: 'EventGroup', id: string, name: string, events: Array<{ __typename?: 'Event', id: string, name: string, statistics: { __typename?: 'EventStatistics', sessions: number, sessionsClosed: number } }> }>, availableMetadata: Array<{ __typename?: 'AllEventMetadataItem', label: string, values: Array<string> }> }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, username: string, createdAt: any, customer: { __typename?: 'Customer', id: string, name: string, email?: Maybe<string>, image: string, phone?: Maybe<string>, createdAt: any } } };

export type GetQuestionTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionTypesQuery = { __typename?: 'Query', questionTypes?: Maybe<Array<{ __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean }>> };

export type GetQuestionTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetQuestionTypeQuery = { __typename?: 'Query', questionType?: Maybe<{ __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean }> };

export type GetQuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionnairesQuery = { __typename?: 'Query', questionnaires?: Maybe<Array<{ __typename?: 'Questionnaire', id: string, name: string, official: boolean, questions: Array<{ __typename?: 'Question', id: string, text: string, required?: Maybe<boolean>, type: { __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean }, nextCondition?: Maybe<{ __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string }> }>, eventTypes: Array<{ __typename?: 'EventType', id: string, name: string, createdAt: any, availableMetadata: Array<{ __typename?: 'AllEventMetadataItem', label: string, values: Array<string> }> }> }>> };

export type GetQuestionnaireQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetQuestionnaireQuery = { __typename?: 'Query', questionnaire?: Maybe<{ __typename?: 'Questionnaire', id: string, name: string, official: boolean, questions: Array<{ __typename?: 'Question', id: string, text: string, required?: Maybe<boolean>, type: { __typename?: 'QuestionType', id: string, name: string, style: QuestionStyle, options: any, isInUse: boolean }, nextCondition?: Maybe<{ __typename?: 'QuestionNextCondition', nextQuestion: string, condition: string }> }>, eventTypes: Array<{ __typename?: 'EventType', id: string, name: string, createdAt: any, availableMetadata: Array<{ __typename?: 'AllEventMetadataItem', label: string, values: Array<string> }> }> }> };

export const AccessCodeBatchFragmentDoc = gql`
    fragment AccessCodeBatch on AccessCodeBatch {
  id
  totalCodes
  codesUsed
  codesClosed
  createdAt
}
    `;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  id
  name
  email
  image
  phone
  createdAt
}
    `;
export const UserWithCustomerFragmentDoc = gql`
    fragment UserWithCustomer on User {
  id
  username
  createdAt
  customer {
    ...Customer
  }
}
    ${CustomerFragmentDoc}`;
export const QuestionnaireMetaFragmentDoc = gql`
    fragment QuestionnaireMeta on Questionnaire {
  id
  name
  official
}
    `;
export const QuestionTypeFragmentDoc = gql`
    fragment QuestionType on QuestionType {
  id
  name
  style
  options
  isInUse
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  id
  text
  required
  type {
    ...QuestionType
  }
  nextCondition {
    nextQuestion
    condition
  }
}
    ${QuestionTypeFragmentDoc}`;
export const AllEventMetadataItemFragmentDoc = gql`
    fragment AllEventMetadataItem on AllEventMetadataItem {
  label
  values
}
    `;
export const EventTypeMetaFragmentDoc = gql`
    fragment EventTypeMeta on EventType {
  id
  name
  createdAt
  availableMetadata {
    ...AllEventMetadataItem
  }
}
    ${AllEventMetadataItemFragmentDoc}`;
export const FullQuestionnaireFragmentDoc = gql`
    fragment FullQuestionnaire on Questionnaire {
  ...QuestionnaireMeta
  questions {
    ...Question
  }
  eventTypes {
    ...EventTypeMeta
  }
}
    ${QuestionnaireMetaFragmentDoc}
${QuestionFragmentDoc}
${EventTypeMetaFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    error
    token
    user {
      id
      username
      createdAt
      customer {
        id
        name
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateQuestionnaireDocument = gql`
    mutation CreateQuestionnaire($name: String!, $questions: [ID!]!) {
  createQuestionnaire(name: $name, questions: $questions) {
    ...FullQuestionnaire
  }
}
    ${FullQuestionnaireFragmentDoc}`;
export type CreateQuestionnaireMutationFn = Apollo.MutationFunction<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;

/**
 * __useCreateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCreateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionnaireMutation, { data, loading, error }] = useCreateQuestionnaireMutation({
 *   variables: {
 *      name: // value for 'name'
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useCreateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>(CreateQuestionnaireDocument, options);
      }
export type CreateQuestionnaireMutationHookResult = ReturnType<typeof useCreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationResult = Apollo.MutationResult<CreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;
export const UpdateQuestionnaireDocument = gql`
    mutation UpdateQuestionnaire($id: ID!, $name: String!, $questions: [ID!]!) {
  updateQuestionnaire(id: $id, name: $name, questions: $questions)
}
    `;
export type UpdateQuestionnaireMutationFn = Apollo.MutationFunction<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;

/**
 * __useUpdateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireMutation, { data, loading, error }] = useUpdateQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useUpdateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>(UpdateQuestionnaireDocument, options);
      }
export type UpdateQuestionnaireMutationHookResult = ReturnType<typeof useUpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationResult = Apollo.MutationResult<UpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($text: String!, $typeId: ID!, $required: Boolean!) {
  createQuestion(text: $text, typeId: $typeId, required: $required) {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      text: // value for 'text'
 *      typeId: // value for 'typeId'
 *      required: // value for 'required'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($id: ID!, $text: String!, $typeId: ID!, $required: Boolean!, $nextQuestionId: ID, $nextCondition: String) {
  updateQuestion(
    id: $id
    text: $text
    type: $typeId
    required: $required
    nextQuestionId: $nextQuestionId
    nextCondition: $nextCondition
  )
}
    `;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      typeId: // value for 'typeId'
 *      required: // value for 'required'
 *      nextQuestionId: // value for 'nextQuestionId'
 *      nextCondition: // value for 'nextCondition'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const UpdateQuestionTypeDocument = gql`
    mutation UpdateQuestionType($id: ID!, $name: String!, $style: QuestionStyle!, $options: JSONObject!) {
  updateQuestionType(id: $id, name: $name, style: $style, options: $options)
}
    `;
export type UpdateQuestionTypeMutationFn = Apollo.MutationFunction<UpdateQuestionTypeMutation, UpdateQuestionTypeMutationVariables>;

/**
 * __useUpdateQuestionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionTypeMutation, { data, loading, error }] = useUpdateQuestionTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      style: // value for 'style'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateQuestionTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionTypeMutation, UpdateQuestionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionTypeMutation, UpdateQuestionTypeMutationVariables>(UpdateQuestionTypeDocument, options);
      }
export type UpdateQuestionTypeMutationHookResult = ReturnType<typeof useUpdateQuestionTypeMutation>;
export type UpdateQuestionTypeMutationResult = Apollo.MutationResult<UpdateQuestionTypeMutation>;
export type UpdateQuestionTypeMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionTypeMutation, UpdateQuestionTypeMutationVariables>;
export const CreateQuestionTypeDocument = gql`
    mutation CreateQuestionType($name: String!, $style: QuestionStyle!, $options: JSONObject!) {
  createQuestionType(name: $name, style: $style, options: $options) {
    id
    name
    style
    options
    isInUse
  }
}
    `;
export type CreateQuestionTypeMutationFn = Apollo.MutationFunction<CreateQuestionTypeMutation, CreateQuestionTypeMutationVariables>;

/**
 * __useCreateQuestionTypeMutation__
 *
 * To run a mutation, you first call `useCreateQuestionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionTypeMutation, { data, loading, error }] = useCreateQuestionTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      style: // value for 'style'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useCreateQuestionTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionTypeMutation, CreateQuestionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionTypeMutation, CreateQuestionTypeMutationVariables>(CreateQuestionTypeDocument, options);
      }
export type CreateQuestionTypeMutationHookResult = ReturnType<typeof useCreateQuestionTypeMutation>;
export type CreateQuestionTypeMutationResult = Apollo.MutationResult<CreateQuestionTypeMutation>;
export type CreateQuestionTypeMutationOptions = Apollo.BaseMutationOptions<CreateQuestionTypeMutation, CreateQuestionTypeMutationVariables>;
export const DeleteQuestionTypeDocument = gql`
    mutation DeleteQuestionType($id: ID!) {
  deleteQuestionType(id: $id)
}
    `;
export type DeleteQuestionTypeMutationFn = Apollo.MutationFunction<DeleteQuestionTypeMutation, DeleteQuestionTypeMutationVariables>;

/**
 * __useDeleteQuestionTypeMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionTypeMutation, { data, loading, error }] = useDeleteQuestionTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionTypeMutation, DeleteQuestionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionTypeMutation, DeleteQuestionTypeMutationVariables>(DeleteQuestionTypeDocument, options);
      }
export type DeleteQuestionTypeMutationHookResult = ReturnType<typeof useDeleteQuestionTypeMutation>;
export type DeleteQuestionTypeMutationResult = Apollo.MutationResult<DeleteQuestionTypeMutation>;
export type DeleteQuestionTypeMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionTypeMutation, DeleteQuestionTypeMutationVariables>;
export const GenerateBatchDocument = gql`
    mutation GenerateBatch($eventId: ID!, $amount: Int!) {
  generated: generateAccessCodeBatch(eventId: $eventId, amount: $amount) {
    id
    totalCodes
    accessCodes {
      tan
    }
    createdAt
  }
}
    `;
export type GenerateBatchMutationFn = Apollo.MutationFunction<GenerateBatchMutation, GenerateBatchMutationVariables>;

/**
 * __useGenerateBatchMutation__
 *
 * To run a mutation, you first call `useGenerateBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateBatchMutation, { data, loading, error }] = useGenerateBatchMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useGenerateBatchMutation(baseOptions?: Apollo.MutationHookOptions<GenerateBatchMutation, GenerateBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateBatchMutation, GenerateBatchMutationVariables>(GenerateBatchDocument, options);
      }
export type GenerateBatchMutationHookResult = ReturnType<typeof useGenerateBatchMutation>;
export type GenerateBatchMutationResult = Apollo.MutationResult<GenerateBatchMutation>;
export type GenerateBatchMutationOptions = Apollo.BaseMutationOptions<GenerateBatchMutation, GenerateBatchMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($id: ID!, $name: String!, $metadata: [EventMetadataItemInput!]!) {
  updateEvent(id: $id, name: $name, metadata: $metadata) {
    id
    name
    metadata {
      label
      value
    }
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const CreateEventTypeDocument = gql`
    mutation CreateEventType($name: String!, $questionnaire: ID!) {
  createEventType(name: $name, questionnaire: $questionnaire) {
    ...EventTypeMeta
    questionnaire {
      ...QuestionnaireMeta
    }
    events {
      id
    }
  }
}
    ${EventTypeMetaFragmentDoc}
${QuestionnaireMetaFragmentDoc}`;
export type CreateEventTypeMutationFn = Apollo.MutationFunction<CreateEventTypeMutation, CreateEventTypeMutationVariables>;

/**
 * __useCreateEventTypeMutation__
 *
 * To run a mutation, you first call `useCreateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventTypeMutation, { data, loading, error }] = useCreateEventTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      questionnaire: // value for 'questionnaire'
 *   },
 * });
 */
export function useCreateEventTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventTypeMutation, CreateEventTypeMutationVariables>(CreateEventTypeDocument, options);
      }
export type CreateEventTypeMutationHookResult = ReturnType<typeof useCreateEventTypeMutation>;
export type CreateEventTypeMutationResult = Apollo.MutationResult<CreateEventTypeMutation>;
export type CreateEventTypeMutationOptions = Apollo.BaseMutationOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>;
export const CreateEventGroupDocument = gql`
    mutation CreateEventGroup($name: String!, $eventTypeId: ID!) {
  createEventGroup(name: $name, eventTypeId: $eventTypeId) {
    id
  }
}
    `;
export type CreateEventGroupMutationFn = Apollo.MutationFunction<CreateEventGroupMutation, CreateEventGroupMutationVariables>;

/**
 * __useCreateEventGroupMutation__
 *
 * To run a mutation, you first call `useCreateEventGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventGroupMutation, { data, loading, error }] = useCreateEventGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      eventTypeId: // value for 'eventTypeId'
 *   },
 * });
 */
export function useCreateEventGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventGroupMutation, CreateEventGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventGroupMutation, CreateEventGroupMutationVariables>(CreateEventGroupDocument, options);
      }
export type CreateEventGroupMutationHookResult = ReturnType<typeof useCreateEventGroupMutation>;
export type CreateEventGroupMutationResult = Apollo.MutationResult<CreateEventGroupMutation>;
export type CreateEventGroupMutationOptions = Apollo.BaseMutationOptions<CreateEventGroupMutation, CreateEventGroupMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($name: String!, $eventGroupId: ID!, $metadata: [EventMetadataItemInput!]!) {
  createEvent(name: $name, eventGroupId: $eventGroupId, metadata: $metadata) {
    id
    name
    statistics {
      sessions
      sessionsClosed
    }
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      eventGroupId: // value for 'eventGroupId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const GetEventDetailsDocument = gql`
    query GetEventDetails($id: ID!) {
  event(id: $id) {
    id
    name
    tan
    metadata {
      label
      value
    }
    accessCodeBatches {
      ...AccessCodeBatch
    }
    eventGroup {
      id
      name
    }
    eventType {
      id
      name
      questionnaire {
        id
        name
      }
      availableMetadata {
        label
        values
      }
    }
  }
}
    ${AccessCodeBatchFragmentDoc}`;

/**
 * __useGetEventDetailsQuery__
 *
 * To run a query within a React component, call `useGetEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEventDetailsQuery, GetEventDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventDetailsQuery, GetEventDetailsQueryVariables>(GetEventDetailsDocument, options);
      }
export function useGetEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventDetailsQuery, GetEventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventDetailsQuery, GetEventDetailsQueryVariables>(GetEventDetailsDocument, options);
        }
export type GetEventDetailsQueryHookResult = ReturnType<typeof useGetEventDetailsQuery>;
export type GetEventDetailsLazyQueryHookResult = ReturnType<typeof useGetEventDetailsLazyQuery>;
export type GetEventDetailsQueryResult = Apollo.QueryResult<GetEventDetailsQuery, GetEventDetailsQueryVariables>;
export const GetEventTypesDocument = gql`
    query GetEventTypes {
  eventTypes {
    id
    name
    questionnaire {
      ...QuestionnaireMeta
    }
    events {
      id
      name
    }
  }
}
    ${QuestionnaireMetaFragmentDoc}`;

/**
 * __useGetEventTypesQuery__
 *
 * To run a query within a React component, call `useGetEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetEventTypesQuery, GetEventTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventTypesQuery, GetEventTypesQueryVariables>(GetEventTypesDocument, options);
      }
export function useGetEventTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventTypesQuery, GetEventTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventTypesQuery, GetEventTypesQueryVariables>(GetEventTypesDocument, options);
        }
export type GetEventTypesQueryHookResult = ReturnType<typeof useGetEventTypesQuery>;
export type GetEventTypesLazyQueryHookResult = ReturnType<typeof useGetEventTypesLazyQuery>;
export type GetEventTypesQueryResult = Apollo.QueryResult<GetEventTypesQuery, GetEventTypesQueryVariables>;
export const GetEventTypeDocument = gql`
    query GetEventType($id: ID!) {
  eventType(id: $id) {
    ...EventTypeMeta
    questionnaire {
      ...QuestionnaireMeta
    }
    eventGroups {
      id
      name
      events {
        id
        name
        statistics {
          sessions
          sessionsClosed
        }
      }
    }
  }
}
    ${EventTypeMetaFragmentDoc}
${QuestionnaireMetaFragmentDoc}`;

/**
 * __useGetEventTypeQuery__
 *
 * To run a query within a React component, call `useGetEventTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventTypeQuery(baseOptions: Apollo.QueryHookOptions<GetEventTypeQuery, GetEventTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventTypeQuery, GetEventTypeQueryVariables>(GetEventTypeDocument, options);
      }
export function useGetEventTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventTypeQuery, GetEventTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventTypeQuery, GetEventTypeQueryVariables>(GetEventTypeDocument, options);
        }
export type GetEventTypeQueryHookResult = ReturnType<typeof useGetEventTypeQuery>;
export type GetEventTypeLazyQueryHookResult = ReturnType<typeof useGetEventTypeLazyQuery>;
export type GetEventTypeQueryResult = Apollo.QueryResult<GetEventTypeQuery, GetEventTypeQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserWithCustomer
  }
}
    ${UserWithCustomerFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetQuestionTypesDocument = gql`
    query GetQuestionTypes {
  questionTypes {
    ...QuestionType
  }
}
    ${QuestionTypeFragmentDoc}`;

/**
 * __useGetQuestionTypesQuery__
 *
 * To run a query within a React component, call `useGetQuestionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>(GetQuestionTypesDocument, options);
      }
export function useGetQuestionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>(GetQuestionTypesDocument, options);
        }
export type GetQuestionTypesQueryHookResult = ReturnType<typeof useGetQuestionTypesQuery>;
export type GetQuestionTypesLazyQueryHookResult = ReturnType<typeof useGetQuestionTypesLazyQuery>;
export type GetQuestionTypesQueryResult = Apollo.QueryResult<GetQuestionTypesQuery, GetQuestionTypesQueryVariables>;
export const GetQuestionTypeDocument = gql`
    query GetQuestionType($id: ID!) {
  questionType(id: $id) {
    ...QuestionType
  }
}
    ${QuestionTypeFragmentDoc}`;

/**
 * __useGetQuestionTypeQuery__
 *
 * To run a query within a React component, call `useGetQuestionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionTypeQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>(GetQuestionTypeDocument, options);
      }
export function useGetQuestionTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>(GetQuestionTypeDocument, options);
        }
export type GetQuestionTypeQueryHookResult = ReturnType<typeof useGetQuestionTypeQuery>;
export type GetQuestionTypeLazyQueryHookResult = ReturnType<typeof useGetQuestionTypeLazyQuery>;
export type GetQuestionTypeQueryResult = Apollo.QueryResult<GetQuestionTypeQuery, GetQuestionTypeQueryVariables>;
export const GetQuestionnairesDocument = gql`
    query GetQuestionnaires {
  questionnaires {
    ...FullQuestionnaire
  }
}
    ${FullQuestionnaireFragmentDoc}`;

/**
 * __useGetQuestionnairesQuery__
 *
 * To run a query within a React component, call `useGetQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>(GetQuestionnairesDocument, options);
      }
export function useGetQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>(GetQuestionnairesDocument, options);
        }
export type GetQuestionnairesQueryHookResult = ReturnType<typeof useGetQuestionnairesQuery>;
export type GetQuestionnairesLazyQueryHookResult = ReturnType<typeof useGetQuestionnairesLazyQuery>;
export type GetQuestionnairesQueryResult = Apollo.QueryResult<GetQuestionnairesQuery, GetQuestionnairesQueryVariables>;
export const GetQuestionnaireDocument = gql`
    query GetQuestionnaire($id: ID!) {
  questionnaire(id: $id) {
    ...FullQuestionnaire
  }
}
    ${FullQuestionnaireFragmentDoc}`;

/**
 * __useGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>(GetQuestionnaireDocument, options);
      }
export function useGetQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>(GetQuestionnaireDocument, options);
        }
export type GetQuestionnaireQueryHookResult = ReturnType<typeof useGetQuestionnaireQuery>;
export type GetQuestionnaireLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireLazyQuery>;
export type GetQuestionnaireQueryResult = Apollo.QueryResult<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>;