import React, { useState } from "react";
import { useCombobox } from "downshift";
import { Listbox } from "@headlessui/react";
import classNames from "classnames";

export function Autocomplete({
  label,
  items,
  value,
  onChange,
}: {
  label: string;
  items: string[];
  value: string;
  onChange: (newValue: string) => void;
}) {
  const [inputItems, setInputItems] = useState(items);
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    selectedItem: value,
    onInputValueChange: ({ inputValue }) => {
      onChange(inputValue ?? '');
      setInputItems(
        inputValue?.length
          ? items.filter((item) =>
              item.toLowerCase().startsWith(inputValue.toLowerCase())
            )
          : items
      );
    },
  });
  return (
    <div>
      <div className="inline-block ml-2" {...getComboboxProps()}>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
          {...getLabelProps()}
        >
          {label}
        </label>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              {...getInputProps()}
              className="focus:ring-red-500 focus:border-red-500 block w-full px-2 rounded-none rounded-l-md sm:text-sm border border-gray-300"
            />
          </div>
          <button
            type="button"
            {...getToggleButtonProps()}
            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <span className="font-bold text-gray-400">&#8595;</span>
          </button>
        </div>
      </div>

      <ul
        {...getMenuProps()}
        className={classNames(
          "absolute z-10 mt-1 w-48 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm",
          !isOpen && "hidden"
        )}
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              className={classNames(
                highlightedIndex === index
                  ? "text-white bg-red-600"
                  : "text-gray-900",
                "cursor-default select-none relative py-2 pl-3 pr-9"
              )}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
      </ul>

      {/* <ul
        {...getMenuProps()}
        className="max-h-24 max-w-6xl overflow-y-scroll bg-gray-200 p-0 list-none relative"
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              style={
                highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
      </ul> */}
    </div>
  );
}
