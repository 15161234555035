import React, { useContext } from "react";
import { client } from "./apolloClient";
import { gql } from "@apollo/client";
import { LoginDocument } from "./generated/graphql";

export const SessionContext = React.createContext({});

export function useSession() {
  return useContext(SessionContext);
}

let token = localStorage.getItem("token");

export function useToken() {
  return token;
}

export function isLoggedIn() {
  return !!token;
}

export function logout() {
  token = null;
  localStorage.removeItem("token");
  client.clearStore();
}

export async function login(username: string, password: string) {
  console.log("Logging in");
  const {
    data: { login: result },
  } = await client.mutate({
    mutation: LoginDocument,
    variables: { username, password },
  });
  if (result.error) throw new Error(result.error);
  token = result.token;
  localStorage.setItem("token", result.token);
  return result;
}
