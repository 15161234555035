import React, { useState } from "react";
import { Form, Button, Input, Icon } from "semantic-ui-react";
import update from "immutability-helper";
import Backend from "react-dnd-html5-backend";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";

export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

type QuestionStyle =
  | "SHORTTEXT"
  | "LONGTEXT"
  | "DROPDOWN"
  | "RADIO"
  | "INT"
  | "FLOAT"
  | "SLIDER";

export const questionStyles: {
  [style: string]: {
    label: string;
    Settings: React.FC<{
      disabled: boolean;
      options: Record<string, unknown>;
      onChange: (options: Record<string, unknown>) => void;
    }>;
  };
} = {
  SHORTTEXT: {
    label: "kleines Textfeld",
    Settings: ({ disabled, options, onChange: handleChange }) => {
      return (
        <>
          <Form.Input
            disabled={disabled}
            fluid
            label="Mindestlänge"
            placeholder="Mindestlänge"
            value={options.min}
            onChange={(e) => handleChange({ ...options, min: e.target.value })}
          />
          <Form.Input
            disabled={disabled}
            fluid
            label="Maximallänge"
            placeholder="Maximallänge"
            value={options.max}
            onChange={(e) => handleChange({ ...options, max: e.target.value })}
          />
          <Form.Input
            disabled={disabled}
            fluid
            label="Regulärer Ausdruck"
            placeholder="Regulärer Ausdruck"
            value={options.regex}
            onChange={(e) =>
              handleChange({ ...options, regex: e.target.value })
            }
          />
        </>
      );
    },
  },
  LONGTEXT: {
    label: "großes Textfeld",
    Settings: ({ disabled, options, onChange: handleChange }) => {
      return (
        <>
          <Form.Input
            disabled={disabled}
            fluid
            label="Mindestlänge"
            placeholder="Mindestlänge"
            value={options.min}
            onChange={(e) => handleChange({ ...options, min: e.target.value })}
          />
          <Form.Input
            disabled={disabled}
            fluid
            label="Maximallänge"
            placeholder="Maximallänge"
            value={options.max}
            onChange={(e) => handleChange({ ...options, max: e.target.value })}
          />
          <Form.Input
            disabled={disabled}
            fluid
            label="Regulärer Ausdruck"
            placeholder="Regulärer Ausdruck"
            value={options.regex}
            onChange={(e) =>
              handleChange({ ...options, regex: e.target.value })
            }
          />
        </>
      );
    },
  },
  DROPDOWN: {
    label: "Auswahlfeld",
    Settings: ({ disabled, options, onChange: handleChange }) => (
      <DndProvider backend={Backend}>
        <List
          disabled={disabled}
          options={options as any}
          handleChange={handleChange}
        />
      </DndProvider>
    ),
  },
  RADIO: {
    label: "Auswahlpunkte",
    Settings: ({ disabled, options, onChange: handleChange }) => (
      <DndProvider backend={Backend}>
        <List
          disabled={disabled}
          options={options as any}
          handleChange={handleChange}
        />
      </DndProvider>
    ),
  },
  INT: {
    label: "Ganzzahl",
    Settings: ({ disabled, options, onChange: handleChange }) => {
      return (
        <>
          <Form.Input
            disabled={disabled}
            type="number"
            fluid
            label="Minimum"
            placeholder="Minimum"
            value={options.min}
            onChange={(e) => handleChange({ ...options, min: e.target.value })}
          />
          <Form.Input
            disabled={disabled}
            type="number"
            fluid
            label="Maximum"
            placeholder="Maximum"
            value={options.max}
            onChange={(e) => handleChange({ ...options, max: e.target.value })}
          />
        </>
      );
    },
  },
  FLOAT: {
    label: "Kommazahl",
    Settings: ({ disabled, options, onChange: handleChange }) => {
      return (
        <>
          <Form.Input
            disabled={disabled}
            type="number"
            fluid
            label="Minimum"
            placeholder="Minimum"
            value={options.min}
            onChange={(e) => handleChange({ ...options, min: e.target.value })}
          />
          <Form.Input
            disabled={disabled}
            type="number"
            fluid
            label="Maximum"
            placeholder="Maximum"
            value={options.max}
            onChange={(e) => handleChange({ ...options, max: e.target.value })}
          />
        </>
      );
    },
  },
  SLIDER: {
    label: "Slider",
    Settings: ({ disabled, options, onChange: handleChange }) => {
      return (
        <>
          <Form.Input
            disabled={disabled}
            type="number"
            fluid
            label="Minimum"
            placeholder="Minimum"
            value={options.min}
            onChange={(e) => handleChange({ ...options, min: e.target.value })}
          />
          <Form.Input
            disabled={disabled}
            type="number"
            fluid
            label="Maximum"
            placeholder="Maximum"
            value={options.max}
            onChange={(e) => handleChange({ ...options, max: e.target.value })}
          />
        </>
      );
    },
  },
};

// a little function to help us with reordering the result
const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const List: React.FC<{
  disabled: boolean;
  options: { values: string[] };
  handleChange: (options: { values: string[] }) => void;
}> = ({ disabled, options, handleChange }) => {
  let counter = 0;
  if (!Array.isArray(options.values)) options.values = [];
  const items = options.values.map((v) => ({
    id: String(counter++),
    content: v,
  }));
  const [newValue, setNewValue] = useState("");

  function handleTextChange(id: string, newValue: string) {
    handleChange({
      values: items.map((item) => (item.id === id ? newValue : item.content)),
    });
  }

  function removeValue(item: typeof items[number]) {
    const i = items.indexOf(item);
    handleChange({
      values: update(items, {
        $splice: [
          [i, 1],
          [i, 0],
        ],
      }).map((i) => i.content),
    });
  }

  return (
    <div>
      <h3>Auswahlpunkte</h3>
      <DragDropContext
        onDragEnd={(result) => {
          // dropped outside the list
          if (!result.destination) {
            return;
          }

          handleChange({
            values: reorder(
              items,
              result.source.index,
              result.destination.index
            ).map((i) => i.content),
          });
        }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classNames(
                "p-4",
                snapshot.isDraggingOver ? "bg-red-200" : "bg-gray-200"
              )}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classNames(
                        "p-8 mb-4 flex items-center rounded",
                        snapshot.isDragging ? "bg-blue-200" : "bg-white"
                      )}
                    >
                      <Icon name="move" />
                      <Input
                        disabled={disabled}
                        type="text"
                        value={item.content}
                        onChange={(e) =>
                          handleTextChange(item.id, e.target.value)
                        }
                      />
                      <Button
                        disabled={disabled}
                        style={{ marginLeft: ".25em" }}
                        icon
                        onClick={() => removeValue(item)}
                      >
                        <Icon name="trash" />
                      </Button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Form.Input
        disabled={disabled}
        fluid
        label="Neuer Eintrag"
        placeholder="Neuer Eintrag"
        value={newValue}
        onChange={(e) => setNewValue(e.target.value)}
      />
      <Button
        disabled={disabled}
        icon
        labelPosition="left"
        onClick={() => {
          handleChange({ values: [...options.values, newValue] });
        }}
      >
        <Icon name="plus" />
        Hinzufügen
      </Button>
    </div>
  );
};
