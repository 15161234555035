import React, { useState, useEffect } from "react";
import { useToken } from "../session";
import { useQuery, gql } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import {
  Header,
  Segment,
  Button,
  Icon,
  Message,
  Accordion,
  Statistic,
} from "semantic-ui-react";
import { client } from "../apolloClient";
import { Grid, CustomStatistic } from "./Dashboard";
import {
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Plot from "react-plotly.js";
import { PageContent } from "../components/Layout";
import { Autocomplete } from "../components/Autocomplete";

const GET_EVENT_TYPE = gql`
  query EventType($id: ID!) {
    eventType(id: $id) {
      id
      name
      questionnaire {
        id
        name
      }
      eventGroups {
        id
        name
        events {
          id
          name
          statistics {
            sessions
            sessionsClosed
          }
          metadata {
            label
            value
          }
        }
      }
      availableMetadata {
        label
        values
      }
    }
  }
`;

const EVALUATE = gql`
  query Evaluation($eventType: ID!, $events: [ID!]!) {
    evaluation(eventType: $eventType, eventsToInclude: $events) {
      startedSessions
      endedSessions
      totalAnswers
      questions {
        question {
          id
          text
          type {
            id
            name
            style
            options
          }
        }
        results {
          answer
          absolute
        }
        plotly
      }
    }
  }
`;

export default function EventTypeEvaluation() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_EVENT_TYPE, {
    variables: { id },
  });

  const metaAvail = data?.eventType?.availableMetadata || [];

  const [metaKey, setMetaKey] = useState(
    metaAvail.length === 1 ? metaAvail[0].label : ""
  );
  useEffect(() => {
    const metaAvail = data?.eventType?.availableMetadata || [];
    if (metaKey === "")
      setMetaKey(metaAvail.length === 1 ? metaAvail[0].label : "");
  }, [data]);
  const [metaValue, setMetaValue] = useState("");
  const [selectedEvents, setSelectedEvents] = useState([]);
  useEffect(() => {
    if (!data?.eventType) return;
    setSelectedEvents(
      data.eventType.eventGroups
        .reduce((acc, group) => [...acc, ...group.events], [])
        .filter((event) => {
          for (const item of event.metadata) {
            if (item.label === metaKey && item.value === metaValue) return true;
          }
          return false;
        })
        .map((e) => e.id)
    );
  }, [data, metaKey, metaValue]);

  const [evaluation, setEvaluation] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.log(error);
    return <p>Error :(</p>;
  }

  const { eventType } = data;
  return (
    <PageContent heading={`Evaluation ${data?.eventType.name || ""}`}>
      <h2 className="text-xl font-bold mb-4 pt-2">
        Wählen Sie alle auszuwertenden Unterrichte aus und klicken Sie
        anschließend auf "Auswerten"
      </h2>
      <div className="bg-white shadow overflow-hidden sm:rounded-md px-4 py-5 sm:px-6 mb-6">
        <h3 className="text-lg font-bold my-4">
          Schnellauswahl nach Metadaten
        </h3>
        <div className="flex">
          <Autocomplete
            items={metaAvail.map((i) => i.label)}
            label={"Name"}
            selectedItem={metaKey}
            onChange={(value) => setMetaKey(value)}
          />
          <Autocomplete
            items={metaAvail.find((i) => i.label === metaKey)?.values || []}
            label={"Wert"}
            selectedItem={metaValue}
            onChange={(value) => setMetaValue(value)}
          />
        </div>
      </div>
      <ul className="space-y-3">
        {data.eventType.eventGroups
          .filter((group) => group.events.length > 0)
          .map((group) => {
            return (
              <li
                key={group.id}
                className="bg-white shadow overflow-hidden sm:rounded-md"
              >
                <div
                  key={group.id}
                  className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6"
                >
                  <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-2">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {group.name}
                      </h3>
                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                      <button
                        type="button"
                        className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-dark"
                        onClick={() => {
                          setSelectedEvents([
                            ...selectedEvents,
                            ...group.events.map((e) => e.id),
                          ]);
                        }}
                      >
                        Alle Unterrichte auswählen
                      </button>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-4 sm:px-6 gap-2 grid grid-cols-5">
                  {group.events.map((event) => {
                    return (
                      <button
                        type="button"
                        onClick={() =>
                          selectedEvents.indexOf(event.id) >= 0
                            ? setSelectedEvents(
                                selectedEvents.filter((i) => i !== event.id)
                              )
                            : setSelectedEvents([...selectedEvents, event.id])
                        }
                        className={classNames(
                          "border rounded px-4 py-2 hover:bg-gray-100 inline-flex flex-col",
                          selectedEvents.indexOf(event.id) >= 0 &&
                            "border-red-dark"
                        )}
                      >
                        <div>
                          {event.name}{" "}
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            {/* {event.statistics.sessionsClosed} /{" "} */}
                            {event.statistics.sessions}
                          </span>
                        </div>
                        {event.metadata.length > 0 && (
                          <div className="flex mt-2">
                            {event.metadata.map(({ label, value }) => (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                <b>{label}:</b>
                                <span>&nbsp;{value}</span>
                              </span>
                            ))}
                          </div>
                        )}
                      </button>
                    );
                  })}
                </div>
              </li>
            );
          })}
      </ul>

      {/* <Segment attached="bottom" loading={loading}>
        {error && <Message negative>{error.message}</Message>}

        <Table columns={columns} data={data ? data.eventType.events : []} />
      </Segment> */}

      <Header attached="top" as="h1">
        Auswertung
      </Header>
      <Segment attached="bottom" loading={false}>
        {selectedEvents.length === 0 && (
          <Message negative>Erst Veranstaltungen auswählen</Message>
        )}
        {error && <Message negative>{error.message}</Message>}

        <Button
          onClick={() =>
            client
              .query({
                query: EVALUATE,
                variables: { eventType: eventType.id, events: selectedEvents },
              })
              .then(({ data }) => setEvaluation(data.evaluation))
          }
        >
          Auswerten
        </Button>

        {evaluation && (
          <>
            <Grid>
              <Statistic>
                <Statistic.Value>{evaluation.startedSessions}</Statistic.Value>
                <Statistic.Label>Sitzungen</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{evaluation.endedSessions}</Statistic.Value>
                <Statistic.Label>beendete Sitzungen</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{evaluation.totalAnswers}</Statistic.Value>
                <Statistic.Label>Antworten</Statistic.Label>
              </Statistic>
            </Grid>

            <Accordion fluid styled>
              {evaluation.questions.map((q, i) => (
                <>
                  <Accordion.Title
                    active={activeIndex === i}
                    index={i}
                    onClick={() => setActiveIndex(i)}
                  >
                    <Icon name="dropdown" />
                    {q.question.text}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === i}>
                    <QuestionEval question={q} evaluation={evaluation} />
                  </Accordion.Content>
                </>
              ))}
            </Accordion>
          </>
        )}
      </Segment>
    </PageContent>
  );
}

function QuestionEval({ question, evaluation }) {
  console.log(question.plotly);
  return (
    <Plot
      data={question.plotly}
      layout={{ width: 800, height: 300, barmode: "stack" }}
    />
  );

  return (
    <BarChart
      width={800}
      height={300}
      data={question.results}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="answer" />
      <YAxis domain={[0, evaluation.startedSessions]} />
      <ReferenceLine
        y={evaluation.startedSessions}
        label="Sitzungen"
        stroke="gray"
        strokeDasharray="3 3"
      />
      <Bar dataKey="absolute" fill="#8884d8" />
    </BarChart>
  );
}
