/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import logoSrc from "../FeuerwehrAgentur_LOGO.png";
import { logout } from "../session";
import { Link, useHistory } from "react-router-dom";
import React from "react";
import { useMeQuery } from "../generated/graphql";

const navigation = [
  // { name: "Dashboard", path: "/", exact: true },
  { name: "Veranstaltungen", path: "/eventTypes" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const meQuery = useMeQuery();
  const history = useHistory();
  return (
    <div className="min-h-screen">
      <Disclosure as="nav" className="bg-white shadow-sm">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src={logoSrc}
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src={logoSrc}
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.path}
                        className={classNames(
                          false
                            ? "border-red-dark text-gray-900"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                          "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        )}
                        aria-current={false ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-dark">
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  <button
                    className="block flex-shrink-0 group text-left"
                    onClick={() => {
                      console.log("Logout");
                      logout();
                      history.push("/login");
                    }}
                  >
                    <div className="flex items-center">
                      <div>
                        <div className="flex flex-grow h-9">
                          <img
                            className="h-full w-auto"
                            src={meQuery.data?.me.customer.image}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-semibold text-gray-700 group-hover:text-gray-900">
                          {meQuery.data?.me.customer.name}
                        </p>
                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                          Abmelden
                        </p>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-dark">
                    <span className="sr-only">Menü öffnen</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className={classNames(
                      false
                        ? "bg-indigo-50 border-red-dark text-red-dark"
                        : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                      "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    )}
                    aria-current={false ? "page" : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <button
                  className="block flex-shrink-0 group text-left"
                  onClick={() => {
                    console.log("Logout");
                    logout();
                    history.push("/login");
                  }}
                >
                  <div className="flex items-center">
                    <div>
                      <div className="inline-block h-9 w-9">
                        <img
                          className="h-full w-auto"
                          src={meQuery.data?.me.customer.image}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-semibold text-gray-700 group-hover:text-gray-900">
                        {meQuery.data?.me.customer.name}
                      </p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                        Abmelden
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="py-10">{children}</div>
    </div>
  );
};

export const PageContent: React.FC<
  React.PropsWithChildren<{ heading: string; headerRight?: React.ReactNode }>
> = ({ heading, headerRight, children }) => {
  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            {heading}
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">{children}</div>
      </main>
    </>
  );
};
