import React, { useState } from "react";
import { useToken } from "../session";
import { gql, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { Table } from "../components/Table";
import { Header, Segment, Button, Icon, Input, Form } from "semantic-ui-react";
import { client } from "../apolloClient";
import { API_URL } from "../constants";
import { PageContent } from "../components/Layout";
import QRCode from "qrcode.react";
import { CellProps, Column } from "react-table";
import {
  AccessCodeBatch,
  useGenerateBatchMutation,
  useGetEventDetailsQuery,
  useUpdateEventMutation,
} from "../generated/graphql";
import moment from "moment";
import Breadcrumbs from "../components/Breadcrumbs";

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}: {
  value: string;
  row: { index: number };
  column: { id: unknown };
  updateMyData: (index: number, id: unknown, value: string) => void;
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      value={value}
      className="shadow-sm py-1 px-2 focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 border rounded-md"
      onChange={(e) => {
        setValue(e.target.value);
      }}
      // We'll only update the external data when the input is blurred
      onBlur={() => updateMyData(index, id, value)}
    />
  );
};

export default function EventDetail() {
  const { eventId } = useParams<{ eventId: string }>();
  const [newBatchLength, setNewBatchLength] = useState(20);
  const [generateBatch] = useGenerateBatchMutation({
    variables: { eventId, amount: newBatchLength },
    refetchQueries: ["GetEventDetails"],
    awaitRefetchQueries: true,
  });
  const [updateEvent] = useUpdateEventMutation({
    refetchQueries: ["GetEventDetails"],
    awaitRefetchQueries: true,
  });
  const { loading, error, data } = useGetEventDetailsQuery({
    variables: { id: eventId },
  });

  const columns: Column<AccessCodeBatch>[] = React.useMemo(
    () => [
      {
        Header: "Anzahl",
        accessor: "totalCodes",
      },
      {
        Header: "Davon benutzt",
        accessor: "codesUsed",
      },
      {
        Header: "Erstellt am",
        accessor: "createdAt",
        Cell: ({ cell: { value } }) => moment(value).format("llll [Uhr]"),
      },
      {
        Header: "Aktionen",
        Cell: ({
          cell: {
            row: {
              original: { id },
            },
          },
        }: CellProps<AccessCodeBatch>) => (
          <div>
            <Button
              as={"a"}
              target="_blank"
              href={`${API_URL}/accesscodes-${id}.pdf`}
              icon
              labelPosition="left"
            >
              <Icon name="print" />
              Drucken
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  const [metadata, setMetadata] = useState<{ label: string; value: string }[]>(
    []
  );
  React.useEffect(() => {
    if (data?.event) setMetadata(data.event.metadata);
  }, [data]);

  const metadataColumns: Column<{ label: string; value: string }>[] =
    React.useMemo(
      () => [
        {
          Header: "Beschreibung",
          accessor: "label",
          Cell: EditableCell,
        },
        { Header: "Wert", accessor: "value", Cell: EditableCell },
        {
          Header: "",
          id: "del",
          Cell: ({
            row: { index },
            deleteMetaRow,
          }: CellProps<any> & { deleteMetaRow: (index: number) => void }) => (
            <Button
              negative
              icon
              labelPosition="left"
              onClick={() => deleteMetaRow(index)}
            >
              <Icon name="trash" />
              Entfernen
            </Button>
          ),
        },
      ],
      []
    );

  if (loading) return <p>Loading...</p>;
  if (error || !data?.event) return <p>Error :(</p>;

  const { event } = data;
  const link = `https://feedback.feuerwehragentur.de/link-${event.tan}`;
  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            name: event.eventType.name,
            path: `/eventTypes/${event.eventType.id}`,
          },
          {
            name: event.eventGroup.name,
            path: `/eventTypes/${event.eventType.id}/${event.eventGroup.id}`,
          },
          {
            name: event.name,
            path: `/eventTypes/${event.eventType.id}/${event.eventGroup.id}/${event.id}`,
          },
        ]}
      />
      <PageContent heading={event.name}>
        <Segment attached="bottom" loading={loading}>
          <p>
            Lassen Sie folgenden Link Ihren Teilnehmenden zukommen. Über diesen
            wird der Fragebogen geöffnet. Alternativ kann der QR-Code benutzt
            werden.
          </p>
          Teilnahme-Link:{" "}
          <a target="_blank" rel="noopener noreferrer" href={link}>
            {link}
          </a>
          <QRCode className="mt-4" value={link} size={256} level={"Q"} />
          <p className="mt-2">
            Mit dem QR-Code können die Teilnehmenden direkt zum Fragebogen
            kommen. Das Eingeben des Links entfällt. Mit Rechtsklick auf den
            QR-Code kann dieser heruntergeladen werden.
          </p>
          <br />
          <Header as="h2">Metadaten</Header>
          <p>
            Sie können Metadaten wie z. B. Lehrperson zu einem Unterricht
            hinzufügen, um die Veranstaltung besser evaluieren zu können.
          </p>
          <Table
            renderRowSubComponent={() => null}
            columns={metadataColumns}
            data={metadata}
            // @ts-ignore
            updateMyData={(
              index: number,
              id: "label" | "value",
              value: string
            ) => {
              const copiedMetadata = [...metadata];
              copiedMetadata[index][id] = value;
              setMetadata(copiedMetadata);
            }}
            deleteMetaRow={(index: number) => {
              const cp = [...metadata];
              cp.splice(index, 1);
              setMetadata(cp);
            }}
          />
          {metadata.length === 0 && <div>Keine Metadaten vorhanden.</div>}
          <Button
            positive
            icon
            labelPosition="left"
            onClick={() => setMetadata([...metadata, { label: "", value: "" }])}
          >
            <Icon name="plus" />
            Neue Zeile
          </Button>
          <Button
            positive
            icon
            labelPosition="left"
            onClick={() => {
              updateEvent({
                variables: { id: event.id, name: event.name, metadata },
              }).catch((err) => console.log(err));
            }}
          >
            <Icon name="save" />
            Speichern
          </Button>
          <Header as="h2">TAN-Listen</Header>
          <Table
            columns={columns}
            data={event.accessCodeBatches}
            renderRowSubComponent={() => null}
          />
          <Input
            type="number"
            value={newBatchLength}
            onChange={(e) => setNewBatchLength(parseInt(e.target.value))}
          />
          <Button
            positive
            icon
            labelPosition="left"
            onClick={() => generateBatch()}
          >
            <Icon name="plus" />
            Neue Code-Liste erstellen
          </Button>
          {/* <Header as="h2">Verlosung</Header>
            <Form.Select
                options={[{ text: 'keine', value: undefined }, ...raffles.map(raffle => ({ text: raffle.id, value: raffle.id }))]}
                placeholder='Verlosung auswählen'
                value={event.raffle ? event.raffle.id : undefined}
                onChange={(_, a) => { console.log(a.value) }}
            /> */}
        </Segment>
      </PageContent>
    </>
  );
}
