import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  concat,
  InMemoryCache,
} from "@apollo/client";
import { useToken } from "./session";
import { API_URL } from "./constants";

const httpLink = new HttpLink({
  uri: API_URL + "/graphql",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = useToken();
    if (!token) return { headers };
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });
  return forward(operation);
});

export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});
