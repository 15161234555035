import React, { useEffect, useState } from "react";
import { useToken } from "../session";
import { gql } from "@apollo/client";
import { Link, useParams, useHistory } from "react-router-dom";
import Table from "../components/Table";
import {
  Header,
  Segment,
  Button,
  Icon,
  Form,
  Input,
  Select,
} from "semantic-ui-react";
import { client } from "../apolloClient";
import { PageContent } from "../components/Layout";

const GET_EVENT_GROUP = gql`
  query EventGroup($id: ID!) {
    eventGroup(id: $id) {
      id
      name
      eventType {
        id
        name
      }
      events {
        id
        name
        statistics {
          sessions
          sessionsClosed
        }
      }
    }
  }
`;

const CREATE_EVENT = gql`
  mutation CreateEvent(
    $name: String!
    $eventGroupId: ID!
    $metadata: [EventMetadataItemInput!]!
  ) {
    createEvent(name: $name, eventGroupId: $eventGroupId, metadata: $metadata) {
      id
      name
      statistics {
        sessions
        sessionsClosed
      }
    }
  }
`;

export default function EventGroupDetail() {
  const { groupId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [name, setName] = useState("");
  const [newEventName, setNewEventName] = useState("");

  const [eventGroup, setEventGroup] = useState();

  function refetch() {
    setLoading(true);
    client
      .query({ query: GET_EVENT_GROUP, variables: { id: groupId } })
      .then(({ data: { eventGroup } }) => {
        if (!eventGroup) setError("Veranstaltung nicht gefunden.");
        else {
          setName(eventGroup.name);
          setEventGroup(eventGroup);
        }
        setLoading(false);
      })
      .catch((e) => setError(e.message));
  }

  useEffect(() => {
    refetch();
  }, [groupId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Fehler: {error}</p>;
  if (!eventGroup) return <p>Lade Daten...</p>;

  return (
    <PageContent
      heading={`Veranstaltung ${eventGroup.name} (Veranstaltungstyp: ${eventGroup.eventType.name})`}
    >
      <h2 className="pt-4 mb-4 text-2xl font-bold">Unterrichte</h2>
      {eventGroup.events.length === 0 && (
        <>
          <p>Bisher wurden keine Unterrichte angelegt.</p>
        </>
      )}
      <ul className="space-y-3">
        {eventGroup.events.map((event) => (
          <li
            key={event.id}
            className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md"
          >
            <Link
              to={`/eventTypes/${eventGroup.eventType.id}/${eventGroup.id}/${event.id}`}
            >
              {event.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className="bg-white p-4 rounded-md mt-8 shadow">
        <h2 className="text-2xl font-bold">Neuen Unterricht erstellen</h2>
        <p className="my-4">
          Vergeben Sie einen Namen für den Unterricht. (beispielsweise "Tag 2:
          Brennen und Löschen")
        </p>
        <Form>
          <Form.Group>
            <Input
              style={{ minWidth: "400px" }}
              value={newEventName}
              placeholder="Name"
              onChange={(e) => setNewEventName(e.target.value)}
            />
            <Button
              positive
              icon
              labelPosition="left"
              onClick={() => {
                client
                  .mutate({
                    mutation: CREATE_EVENT,
                    variables: {
                      name: newEventName,
                      eventGroupId: eventGroup.id,
                      metadata: [],
                    },
                  })
                  .then(({ data }) =>
                    history.push(
                      `/eventTypes/${eventGroup.eventType.id}/${eventGroup.id}/${data.createEvent.id}`
                    )
                  )
                  .catch((err) => console.log(err));

                // eslint-disable-next-line no-restricted-globals
              }}
            >
              <Icon name="plus" />
              Neuen Unterricht erstellen
            </Button>
          </Form.Group>
        </Form>
      </div>
    </PageContent>
  );
}
