import { client } from "./apolloClient";
import {
  CreateEventDocument,
  CreateEventGroupDocument,
  CreateEventGroupMutation,
  CreateEventGroupMutationVariables,
  CreateEventMutation,
  CreateEventMutationVariables,
  CreateEventTypeDocument,
  CreateEventTypeMutation,
  CreateEventTypeMutationVariables,
  CreateQuestionDocument,
  CreateQuestionMutation,
  CreateQuestionMutationVariables,
  CreateQuestionnaireDocument,
  CreateQuestionnaireMutation,
  CreateQuestionnaireMutationVariables,
  CreateQuestionTypeDocument,
  CreateQuestionTypeMutation,
  CreateQuestionTypeMutationVariables,
  GenerateBatchDocument,
  GenerateBatchMutation,
  GenerateBatchMutationVariables,
  GetEventTypeDocument,
  GetEventTypeQuery,
  GetEventTypeQueryVariables,
  QuestionFragment,
  QuestionStyle,
  QuestionTypeFragment,
  UpdateQuestionDocument,
  UpdateQuestionMutation,
  UpdateQuestionMutationVariables,
} from "./generated/graphql";

const questionTypes: QuestionTypeFragment[] = [];
let qtId = 1;
function addQuestionType(
  questionType: Omit<QuestionTypeFragment, "id" | "isInUse">
): QuestionTypeFragment {
  const qt = { id: `${++qtId}`, isInUse: true, ...questionType };
  questionTypes.push(qt);
  return qt;
}

type CreateQuestionOptions = {
  id?: string;
  subQuestionNumber?: string;
  nextCondition?: {
    nextQuestion: string;
    condition: string;
  };
  overwriteIncreaseCounter?: boolean;
};

const yesNoQuestionType: QuestionTypeFragment = addQuestionType({
  name: "Ja/Nein",
  style: QuestionStyle.Radio,
  options: { values: ["Ja", "Nein"] },
});
const yesNoNotApplicabaleQuestionType: QuestionTypeFragment = addQuestionType({
  name: "Ja/Nein/Nicht zutreffend",
  style: QuestionStyle.Radio,
  options: { values: ["Ja", "Nein", "Nicht zutreffend"] },
});

const importanceQuestionType: QuestionTypeFragment = addQuestionType({
  name: "Wichtig/Unwichtig",
  style: QuestionStyle.Radio,
  options: {
    values: ["unwichtig", "eher unwichtig", "eher wichtig", "wichtig"],
  },
});
const shortTextQuestionType: QuestionTypeFragment = addQuestionType({
  name: "Kurzer Text",
  style: QuestionStyle.Shorttext,
  options: {},
});
const longTextQuestionType: QuestionTypeFragment = addQuestionType({
  name: "Kurzer Text",
  style: QuestionStyle.Longtext,
  options: {},
});

const intQuestionType = addQuestionType({
  name: "Zahl",
  style: QuestionStyle.Int,
  options: {},
});
const eherJaQuestionType = addQuestionType({
  name: "Eher ja",
  style: QuestionStyle.Radio,
  options: { values: ["Ja", "Eher ja", "Eher nein", "Nein"] },
});
const eherGutQuestionType = addQuestionType({
  name: "Eher ja",
  style: QuestionStyle.Radio,
  options: { values: ["Gut", "Eher gut", "Eher schlecht", "Schlecht"] },
});
const gefordertQuestionType = addQuestionType({
  name: "Gefordert",
  style: QuestionStyle.Radio,
  options: {
    values: [
      "Nicht gefordert",
      "Eher nicht gefordert",
      "Eher stark gefordert",
      "Stark gefordert",
    ],
  },
});
const zufriedenQuestionType = addQuestionType({
  name: "Zufrieden",
  style: QuestionStyle.Radio,
  options: {
    values: [
      "Nicht zufrieden",
      "Eher nicht zufrieden",
      "Eher zufrieden",
      "Sehr zufrieden",
    ],
  },
});
const zuKurzZuLangQuestionType = addQuestionType({
  name: "Zu kurz/lang",
  style: QuestionStyle.Radio,
  options: { values: ["zu kurz", "genau richtig", "zu lang"] },
});
const oftSportQuestionType = addQuestionType({
  name: "Sport Häufigkeit",
  style: QuestionStyle.Radio,
  options: {
    values: [
      "Täglich",
      "3 Mal pro Woche",
      "1 Mal pro Woche",
      "Seltener als 1 Mal pro Woche",
    ],
  },
});
const sportartQuestionType = addQuestionType({
  name: "Sportart",
  style: QuestionStyle.Radio,
  options: {
    values: [
      "Akrobatik",
      "Ausdauersport",
      "Ballsport",
      "Kampfsport",
      "Klettern",
      "Natursport",
      "Kraftsport",
      "Leichtathletik",
      "Radsport",
      "Tanzsport",
      "Turnsport",
      "Wurfsport",
      "Andere",
    ],
    multiple: true,
  },
});

const reasonsQuestionType = addQuestionType({
  name: "Keine Funktion mehr Gründe",
  style: QuestionStyle.Radio,
  options: {
    values: [
      "Berufliche Gründe",
      "Familiäre Gründe",
      "Gesundheitliche Gründe",
      "Finanzieller Aufwand zu groß",
      "Umzug",
      "Schule, Ausbildung",
      "Die Tätigkeit war von vornherein zeitlich begrenzt.",
      "Spannungen und Schwierigkeiten mit untergebenen Kräften",
      "Spannungen und Schwierigkeiten mit vorgesetzten Kräften",
      "Spannungen und Schwierigkeiten mit der Gruppe an sich",
      "Zeitlicher Aufwand zu groß",
      "Ich fühlte mich überfordert.",
      "Ich fühlte mich ausgenutzt.",
      "Das Ziel des Projektes wurde nicht erreicht.",
      "Es gab keine Finanzierung für das Vorhaben.",
      "Es gab nicht genug Leute, die weiter machen wollten.",
      "Die Gruppe/Organisation wurde aufgelöst.",
      "Sonstige",
    ],
    multiple: true,
  },
});

let questionCounter = 0;
function qText(
  text: string,
  sub?: string,
  overwriteIncreaseCounter?: boolean
): string {
  questionCounter =
    questionCounter +
    (overwriteIncreaseCounter === undefined
      ? sub === undefined
        ? 1
        : 0
      : overwriteIncreaseCounter
      ? 1
      : 0);
  let t = `${questionCounter}${sub ?? ""}. ${text}`;
  return t;
}

let questionIdCounter = 0;

function q(
  text: string,
  type: QuestionTypeFragment,
  options?: CreateQuestionOptions
): QuestionFragment {
  return {
    id: options?.id ?? `${++questionIdCounter}`,
    text: qText(
      text,
      options?.subQuestionNumber,
      options?.overwriteIncreaseCounter
    ),
    type,
    nextCondition: options?.nextCondition,
  };
}

export const questions: QuestionFragment[] = [
  q("Sind Sie aktives Mitglied der Einsatzabteilung?", yesNoQuestionType, {
    nextCondition: {
      nextQuestion: "pers02",
      condition: "{{ 'false' if answer == 'Nein' else 'true'}}",
    },
  }),
  radioQuestion(
    "Sind Sie nur vorübergehend oder endgültig nicht mehr Mitglied der Einsatzabteilung?",
    [
      "Ich bin nur vorübergehend inaktiv.",
      "Ich werde endgültig keine dienstlichen Tätigkeiten mehr durchführen.",
    ],
    { subQuestionNumber: "a" }
  ),
  radioQuestion("Sie sind...", ["weiblich", "männlich", "divers"], {
    id: "pers02",
  }),
  q("In welchem Jahr wurden Sie geboren?", intQuestionType),
  q("Wurden Sie in Deutschland geboren?", yesNoQuestionType),
  radioQuestion("Sind Ihre beiden Elternteile in Deutschland geboren?", [
    "Ja, beide",
    "Ein Elternteil",
    "Nein, beide nicht",
  ]),
  radioQuestion("Wie lange leben Sie an Ihrem derzeitigen Wohnort?", [
    "Seit meiner Geburt",
    "Seit weniger als 3 Jahren",
    "Seit mehr als 3 Jahren",
    "Seit mehr als 10 Jahren",
  ]),
  radioQuestion("Leben Sie allein oder mit anderen Personen?", [
    "Allein",
    "Mit anderen Personen",
  ]),
  q("Gibt es in Ihrem Haushalt Kinder unter 12 Jahren?", yesNoQuestionType),
  ...multiRadioQuestions(
    "Welche Faktoren spielen für Sie in Ihrer Lebensführung eine Rolle?",
    [
      "Fleißig und ehrgeizig sein",
      "Einen hohen Lebensstandard haben",
      "Macht und Einfluss haben",
      "Phantasie und Kreativität entwickeln",
      "Nach Sicherheit streben",
      "Sozial Benachteiligte unterstützen",
      "Sich und seine Bedürfnisse gegen andere durchsetzen",
      "Gesetz und Ordnung respektieren",
      "Auch andere Meinungen tolerieren",
      "Sich politisch engagieren",
      "Die guten Dinge des Lebens in vollen Zügen genießen",
      "Sich für den Umweltschutz einsetzen",
    ],
    importanceQuestionType
  ),
  radioQuestion("Sie sind derzeit...", [
    "erwerbstätig",
    "arbeitslos gemeldet",
    "Schüler / Auszubildender",
    "Hausfrau / Hausmann",
    "Rentner oder Pensionär",
    "Student",
    "Sonstiges",
  ]),
  radioQuestion("Welchen Bildungsabschluss haben Sie?", [
    "Keinen",
    "Hauptschulabschluss",
    "Realschulabschluss",
    "Fachhochschulreife",
    "Allgemeine Hochschulreife",
  ]),
  radioQuestion("Welche berufsqualifizierenden Abschlüsse haben Sie?", [
    "Angelernt",
    "Ausbildung",
    "Meisterprüfung",
    "Technikerschule",
    "Berufakademie o. ä.",
    "Fachhochschulabschluss",
    "Universitätsabschluss",
  ]),
  radioQuestion("In welcher beruflichen Stellung sind Sie zurzeit tätig?", [
    "Arbeiter",
    "Angestellter",
    "Beamter",
    "Selbstständig",
    "Nicht berufstätig",
    "Sonstige",
  ]),
  q("Welchen Beruf üben Sie derzeit aus?", shortTextQuestionType),
  q(
    "Wie viele Stunden beträgt Ihre tatsächliche wöchentliche Arbeitszeit?",
    intQuestionType
  ),
  radioQuestion("Wie viele Tage umfasst Ihre Arbeitswoche im Mittel?", [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
  ]),
  radioQuestion(
    "Wie viele Tage Ihrer Arbeitswoche befinden Sie sich im Homeoffice (im Mittel)?",
    ["1", "2", "3", "4", "5", "6", "7"]
  ),
  radioQuestion(
    "Haben Sie Wehrdienst, Zivildienst oder einen Freiwilligendienst geleistet?",
    [
      "Wehrdienst",
      "Zivildienst",
      "FSJ/FÖJ",
      "Anderer Wehrersatz- oder Freiwilligendienst",
      "Weder noch",
    ]
  ),
  radioQuestion(
    "Wo kam damals Ihr hauptsächlicher Anstoß her, in die Feuerwehr einzutreten?",
    [
      "Von einem Familienmitglied, das nicht in der Feuerwehr war",
      "Von einem Familienmitglied, das in der Feuerwehr war/ist",
      "Von einem Feuerwehrmitglied",
      "Wehrführer / Leiter der Feuerwehr",
      "Von einem Zeitungsbericht",
      "Von einer Werbekampagne",
      "Von einem Dritten",
      "Von einem persönlichen Erlebnis",
      "Sonstiges",
    ]
  ),
  radioQuestion("Sind / waren Familienmitglieder in der Feuerwehr?", [
    "Ja, mein Vater",
    "Ja, meine Mutter",
    "Ja, mein Großvater",
    "Ja, meine Großmutter",
    "Ja, mein Bruder",
    "Ja, meine Schwester",
    "Nein",
  ]),
  q("Waren Sie in einer Jugendfeuerwehr?", yesNoQuestionType),
  q(
    "Sind Sie von der Jugendfeuerwehr in die Einsatzabteilung gewechselt?",
    yesNoQuestionType
  ),
  q(
    "Wie viele Jahre sind Sie bei der <u>jetzigen</u> Feuerwehr? (Inklusive gegebenenfalls Jugendfeuerwehr)",
    intQuestionType
  ),
  checkboxQuestion(
    "Sind Sie heute <u>neben der Feuerwehr</u> in einem der folgenden Bereiche ehrenamtlich tätig?",
    [
      "Sport und Bewegung",
      "Jugendarbeit",
      "Politik, Parteien, Wählervereinigung, Interessensvertretungen",
      "Kultur und Musik",
      "Andere Feuerwehr",
      "Berufliche Interessenvertretung außerhalb des Betriebes",
      "Freizeit und Geselligkeit",
      "Bildungsarbeit für Erwachsene",
      "Kirchlicher oder religiöser Bereich",
      "Sozialer Bereich",
      "Umwelt-, Natur- und Tierschutz",
      "Sonstige bürgerschaftliche Aktivität am Wohnort",
      "Gesundheitsbereich",
      "Justiz, Kriminalitätsprävention",
      "Schule, Kindergarten",
      "Hilfsorganisation",
    ],
    {
      nextCondition: {
        condition:
          "{{'true' if not answer or answer.length == 0 else 'false' }}",
        nextQuestion: "enga04",
      },
    }
  ),
  radioQuestion(
    "Wie hoch ist in diesen Bereichen Ihr <u>insgesamtes</u> Stundenengagement <u>pro Woche</u>?",
    [
      "bis 2 Std.",
      "3-5 Std.",
      "6-10 Std.",
      "11-15 Std.",
      "Über 15 Std.",
      "nicht feststellbar, da unregelmäßige Tätigkeit",
    ],
    { subQuestionNumber: "a" }
  ),
  radioQuestion(
    "Was sind Ihre Tätigkeiten in diesen Bereichen?",
    [
      "Aktives Mitglied",
      "Leiter, Trainer, Ausbilder einer Gruppe",
      "Tätigkeit in der Geschäftsführung oder Vorstand",
      "Sonstiges",
    ],
    { subQuestionNumber: "b" }
  ),
  radioQuestion(
    "Sind Sie derzeit neben Ihrer Feuerwehr bei einer ...",
    [
      "weiteren Feuerwehr",
      "Hilfsorganisation",
      "weiteren Feuerwehr und Hilfsorganisation",
      "nichts davon",
    ],
    { id: "enga04" }
  ),
  q(
    "Waren Sie schon einmal in einer anderen Feuerwehr oder Hilfsorganisation und haben diese verlassen?",
    yesNoQuestionType,
    {
      nextCondition: {
        condition: "{{'true' if answer != 'Ja' else 'false'}}",
        nextQuestion: "enga07",
      },
    }
  ),
  checkboxQuestion(
    "Was waren die Gründe dafür, dass Sie schon einmal eine anderen Feuerwehr oder Hilfsorganisation verlassen haben?",
    [
      "Berufliche Gründe",
      "Familiäre Gründe",
      "Gesundheitliche Gründe",
      "Finanzieller Aufwand zu groß",
      "Umzug",
      "Schule, Ausbildung",
      "Die Tätigkeit war von vornherein zeitlich begrenzt.",
      "Spannungen und Schwierigkeiten mit untergebenen Kräften",
      "Spannungen und Schwierigkeiten mit vorgesetzten Kräften",
      "Spannungen und Schwierigkeiten mit der Gruppe an sich",
      "Zeitlicher Aufwand zu groß",
      "Ich fühlte mich überfordert.",
      "Ich fühlte mich ausgenutzt.",
      "Das Ziel des Projektes wurde nicht erreicht.",
      "Es gab keine Finanzierung für das Vorhaben.",
      "Es gab nicht genug Leute, die weiter machen wollten.",
      "Die Gruppe/Organisation wurde aufgelöst.",
      "Sonstiges",
    ],
    { subQuestionNumber: "a" }
  ),
  q(
    "Wie viele Jahre sind Sie insgesamt bei Feuerwehren? Bitte nennen Sie die Summe Ihrer Verdienstzeiten, auch wenn diese bei anderen Feuerwehren entstanden sind.",
    intQuestionType,
    { id: "enga07" }
  ),
  radioQuestion(
    "Welche Aussage trifft am ehesten zu, wenn Sie sich ehrenamtlich engagieren?",
    [
      "Ich will durch mein Engagement vor allem mit anderen Menschen zusammenkommen.",
      "Meine Tätigkeit in der Feuerwehr ist eine Aufgabe, die gemacht werden muss und für die sich schwer jemand findet.",
      "Ich will die Gesellschaft durch mein Engagement in der Feuerwehr zumindest im Kleinen mitgestalten.",
      "Mein Engagement ist auch eine Form von politischen Engagement.",
      "Ich will mich selbst fordern und weiterentwickeln.",
      "Ich möchte einer Tätigkeit nachgehen, die mir Spaß macht.",
    ]
  ),
  checkboxQuestion(
    "Womit könnte die Politik / Gesellschaft die Rahmenbedingungen ändern, um das <u>Ehrenamt im Allgemeinen</u> attraktiv zu steigern, wo wären Verbesserungen nötig?",
    [
      "Es gibt aus meiner Sicht hierzu keinen Bedarf.",
      "Bei der Absicherung durch Haftpflicht- und Unfallversicherung",
      "Bei der steuerlichen Absetzbarkeit von Kosten",
      "Bei der steuerlichen Freistellung von Aufwandsentschädigungen",
      "Bei der Anerkennung der ehrenamtlichen Tätigkeit als berufliches oder ausbildungsbezogenes Praktikum",
      "Bei der öffentlichen Anerkennung durch Ehrungen oder ähnlichem",
      "Bei der öffentlichen Anerkennung durch Berichterstattung in den Medien o.ä",
      "Bei der besseren Beratung und Information über ehrenamtliches Engagement",
      "Sonstiges",
    ]
  ),
  checkboxQuestion(
    "Womit könnte Ihrer Meinung nach Ihre Feuerwehr / Stadt-/Gemeindeverwaltung die Rahmenbedingungen ändern, um das <u>Ehrenamt im Allgemeinen</u> zu fördern, wo wären Verbesserungen nötig?",
    [
      "Es gibt aus meiner Sicht keinen Bedarf, die Attraktivität des Ehrenamtes in der Feuerwehr zu steigern.",
      "Bei der fachlichen Unterstützung der Führungskräfte",
      "Bei der fachlichen Unterstützung der Ausbilder in den Bereichen Methodik und Didaktik",
      "Bei der fachlichen Unterstützung der Ausbilder im Bereich Erwachsenenbildung",
      "Bei der fachlichen Unterstützung der Öffentlichkeitsarbeit",
      "Bei den Weiterbildungsmöglichkeiten",
      "Bei der Anerkennung der Tätigkeit durch die hauptamtlich Tätigen, z.B. in der Verwaltung oder in der Politik",
      "Bei der finanziellen Vergütung für die geleistete Arbeit",
      "Bei einer unbürokratischen Erstattung verauslagter Kosten",
      "Bei der Bereitstellung von Räumlichkeiten und Ausstattungsmitteln für die Arbeit",
      "Bei der Bereitstellung von Finanzmitteln für Projekte",
      "Sonstiges",
    ]
  ),
  checkboxQuestion("Wie ist Ihre persönliche Meinung zum Feuerwehrverein?", [
    "Macht gute Arbeit.",
    "Ist wichtig für mich als Feuerwehrmitglied.",
    "Ist wichtig für den Ortsteil.",
    "Lenkt die Geschicke der Ortsteilfeuerwehr.",
    "Unterstützt die Feuerwehr.",
    "Gewährleistet Brandschutz und allgemeine Hilfe in meinem Ortsteil.",
    "Sonstiges",
  ]),
  radioQuestion(
    "Wie würden Sie Ihre Tätigkeit bei der Feuerwehr am ehesten bezeichnen?",
    [
      "Freiwilligenarbeit",
      "Selbsthilfe",
      "Nebenberufliche Tätigkeit",
      "Hobby",
      "Ehrenamt",
      "Initiativenarbeit",
      "Bürgerschaftliches Engagement",
    ]
  ),
  radioQuestion(
    "Wie häufig üben Sie Tätigkeiten in der Feuerwehr aus, bzw. müssen Sie Zeit für Tätigkeiten in der Feuerwehr aufbringen?",
    [
      "Nahezu jeden Tag",
      "Mehrmals die Woche",
      "Einmal die Woche",
      "Einmal im Monat",
      "Seltener",
    ]
  ),
  checkboxQuestion(
    "In welchen Bereichen Ihres Lebens müssen Sie sich einschränken, um am Feuerwehrdienst teilzunehmen?",
    [
      "Beruf",
      "Nebenjob",
      "Familie / Partnerschaft",
      "Freizeit / Sport",
      "Aus- und Fortbildung",
      "gar nicht",
      "Sonstiges",
    ]
  ),
  checkboxQuestion(
    "Wann stehen Sie der Feuerwehr zeitlich in der Regel zur Verfügung?",
    [
      "0-6 Uhr",
      "6-7 Uhr",
      "7-8 Uhr",
      "8-9 Uhr",
      "9-10 Uhr",
      "10-12 Uhr",
      "12-14 Uhr",
      "14-15 Uhr",
      "15-16 Uhr",
      "16-17 Uhr",
      "17-18 Uhr",
      "18-20 Uhr",
      "20-22 Uhr",
      "22-0 Uhr",
    ]
  ),
  q(
    "Wenn Sie sich nicht in der Stadt/Gemeinde Ihrer Feuerwehr aufhalten, in welchem Ort halten Sie sich dann für gewöhnlich auf?",
    shortTextQuestionType
  ),
  radioQuestion(
    "Wie weit ist die einfache Entfernung Ihres Arbeitsplatzes zu Ihrem Feuerwehrhaus?",
    [
      "Weniger als 10 km",
      "Mehr als 10 km",
      "Mehr als 20 km",
      "Mehr als 30 km",
      "Mehr als 40 km",
      "Mehr als 50 km",
    ]
  ),
  radioQuestion(
    "Wie weit ist die einfache Entfernung Ihres Wohnortes zu Ihrem Feuerwehrhaus?",
    ["1-3 km", "3-5 km", ">= 5 km"]
  ),
  q(
    "Haben Sie Ihren Arbeitgeber informiert, dass Sie Mitglied in der Feuerwehr sind?",
    yesNoQuestionType
  ),
  checkboxQuestion(
    "Unterstützt Sie Ihr Arbeitgeber bei dem Engagement in der Feuerwehr?",
    [
      "Durch Freistellung für Übung und Ausbildung",
      "Ich kann Infrastruktur (Kopierer, Internet, Fax) meines Arbeitgebers nutzen.",
      "Durch Freistellung für Einsätze",
      "Flexible Arbeitszeitgestaltung",
      "Er unterstützt mich nicht.",
      "Sonstiges",
    ]
  ),
  q(
    "Wären Sie grundsätzlich bereit, am Ort Ihres Arbeitsplatzes bei der Freiwilligen Feuerwehr mitzuwirken?",
    yesNoQuestionType
  ),
  q(
    "Bringen Sie Ihre berufliche Kompetenz in den Feuerwehrdienst ein?",
    eherJaQuestionType
  ),
  radioQuestion("Wie lange leben Sie noch in unserer Stadt/Gemeinde?", [
    "vermutlich noch etwa 1 Jahr",
    "vermutlich noch etwa 3 Jahre",
    "vermutlich noch etwa 5 Jahre",
    "vermutlich noch mindestens 10 Jahre",
    "Ich möchte vermutlich für immer hier wohnen.",
  ]),
  q(
    "Erwägen Sie ihren Wohnort zu verlassen, weil Sie Eigentum erwerben möchten und dies hier Ihre Möglichkeiten übersteigt?",
    yesNoQuestionType
  ),
  q(
    "Wären Sie grundsätzlich bereit, in einer anderen Ortsteilfeuerwehr innerhalb Ihrer Feuerwehr mitzuwirken?",
    eherJaQuestionType
  ),
  radioQuestion(
    "Wie stehen Sie grundsätzlich der Zusammenlegung von verschiedenen Ortsteilfeuerwehren gegenüber?",
    [
      "Kommt gar nicht in Frage.",
      "Wenn es sein muss und Sinn ergibt.",
      "Kann man machen. Finde ich ganz okay.",
      "Finde ich eine sehr gute Idee. Bin ich dabei.",
    ]
  ),
  q(
    "Wie finden Sie die Idee, die Zusammenarbeit der Ortsteilfeuerwehren zu erhöhen?",
    eherGutQuestionType
  ),
  q(
    "Wie finden Sie die Idee, Ortsteilfeuerwehren zusammenzulegen, <u>und an einen gemeinsamen Standort zu verlegen</u>?",
    eherGutQuestionType
  ),
  q(
    "Wie finden Sie die Idee, Ortsteilfeuerwehren zusammenzulegen, <u>wenn die einzelnen Standorte erhalten bleiben</u>? (Ein Löschbezirk unter einer Wehrführung)",
    eherGutQuestionType
  ),
  q(
    "Rechnen Sie bei einer eventuellen Zusammenlegung von Ortsteilen mit einer Qualitätsverbesserung bei <u>Ausbildung und Einsätzen</u>",
    eherJaQuestionType
  ),
  q(
    "Rechnen Sie bei einer eventuellen Zusammenlegung von Ortsteilen mit einer Qualitätsverbesserung bei <u>Ausstattung mit Fahrzeugen und Gerätschaften</u>",
    eherJaQuestionType
  ),
  q(
    "Rechnen Sie bei einer eventuellen Zusammenlegung von Ortsteilen mit einer Qualitätsverbesserung bei <u>Unterbringung im Feuerwehrhaus</u>",
    eherJaQuestionType
  ),
  q(
    "Können Sie sich vorstellen, alle Ortsteilfeuerwehren von einem gemeinsamen Standort aus arbeiten zu lassen?",
    eherJaQuestionType
  ),
  ...multiRadioQuestions(
    "Welche Anforderungen stellt der Feuerwehrdienst an Sie?",
    [
      "Organisationstalent",
      "Führungsqualitäten",
      "Hohe Einsatzbereitschaft",
      "Fachwissen",
      "Mit Menschen gut umgehen können",
      "Belastbarkeit",
      "Mit Behörden gut umgehen können",
      "Selbstlosigkeit",
      "Technisches Verständnis",
    ],
    gefordertQuestionType
  ),
  radioQuestion(
    "Wie gut fühlen Sie sich auf  auf die Anforderungen im Feuerwehrdienst vorbereitet?",
    [
      "Unzureichend vorbereitet",
      "Weniger gut vorbereitet",
      "Gut vorbereitet",
      "Sehr gut vorbereitet",
    ],
    {
      nextCondition: {
        nextQuestion: "aue03",
        condition:
          "{{'false' if answer == 'Unzureichend vorbereitet' or answer == 'Weniger gut vorbereitet' else 'true'}}",
      },
    }
  ),
  radioQuestion(
    'Woran liegt es, dass Sie sich auf die Anforderungen im Feuerwehrdienst "unzureichend vorbereitet" oder "weniger gut vorbereitet" fühlen?',
    [
      "Fehlende Ausbildung",
      "Fehlendes Vertrauen in die Kameraden",
      "Fehlendes Vertrauen in eine Führungskraft",
      "Ich bin generell etwas ängstlich.",
      "Anderer Grund",
    ],
    { subQuestionNumber: "a" }
  ),
  radioQuestion(
    "In welchem Umfang haben Sie bei der Feuerwehr Kenntnisse erworben, die Sie woanders einsetzen können?",
    ["In sehr hohem Umfang", "In kleinerem Umfang", "Gar nicht"],
    { id: "aue03" }
  ),
  checkboxQuestion("Haben Sie Erfahrung mit folgenden Ausbildungsformaten?", [
    "Standortausbildung im Ortsteil oder Stadtteil",
    "Orts- und Stadtteilübergreifende Ausbildung",
    "Ausbildung auf Stadt-/Gemeindeebene",
    "Ausbildung auf Landkreisebene",
    "Ausbildung an der Hessischen Landesfeuerwehrschule",
    "Bildungseinrichtungen des Bundes (beispielsweise AKNZ)",
    "Gewerbliche Aus- und Fortbildungsveranstaltungen",
  ]),
  q(
    "Haben Sie bereits an solchen Aus- und Fortbildungsangeboten teilgenommen?",
    yesNoQuestionType
  ),
  radioQuestion(
    "Gibt es für Sie in Ihrer momentanen Position Aus- und Fortbildungsmöglichkeiten?",
    ["Ja", "Nein", "Weiß nicht"]
  ),
  radioQuestion("Wann ist für Sie der optimale Zeitpunkt für Übungsdienste?", [
    "Montags abends",
    "Dienstags abends",
    "Mittwochs abends",
    "Donnerstags abends",
    "Freitags abends",
    "Samstags vormittags",
    "Samstags nachmittags",
    "Samstag ganztags",
    "Sonntags vormittags",
    "Sonntags nachmittags",
    "Sonntag ganztags",
  ]),
  q(
    "Schätzen Sie die Ausbildung in Ihrer Feuerwehr als inhaltlich angemessen ein? Werden die richtigen Themen geschult?",
    eherJaQuestionType
  ),
  q(
    "Schätzen Sie die Ausbildung in Ihrer Feuerwehr als methodisch zeitgemäß ein? Sind Menge des Lehrstoffes die Präsentation angemessen?",
    eherJaQuestionType
  ),
  q(
    "Halten Sie den Zeitansatz für Übungsdienst in Ihrer Feuerwehr für ...",
    zuKurzZuLangQuestionType
  ),
  q(
    "Halten Sie das Ausbildungsangebot an Zusatzausbildungen für ...",
    zuKurzZuLangQuestionType
  ),
  q(
    "An wie vielen Ausbildungsdiensten nehmen Sie im Jahr teil? (<u>Ohne Sonderausbildung wie Atemschutzgeräteträgerlehrgang, ABC-Ausbildungen, Maschinistenlehrgänge, Führungslehrgänge</u>)",
    intQuestionType
  ),
  q(
    "Haben Sie Interesse an ortsteilübergreifender Ausbildung?",
    eherJaQuestionType
  ),
  q("Gibt es in Ihrer Feuerwehr einen Ausbildungsleiter?", yesNoQuestionType),
  q("Würden Sie gerne in der Feuerwehr Sport treiben?", yesNoQuestionType, {
    nextCondition: {
      nextQuestion: "aue15",
      condition: "{{'false' if answer == 'Ja' else 'true'}}",
    },
  }),
  q(
    "Welchen Sport würden Sie in der Feuerwehr gerne betreiben?",
    sportartQuestionType,
    { subQuestionNumber: "a" }
  ),
  q(
    "Wie oft würden Sie gerne in der Feuerwehr Sport treiben?",
    oftSportQuestionType,
    { subQuestionNumber: "b" }
  ),
  q("Treiben Sie regelmäßig Sport?", yesNoQuestionType, {
    id: "aue15",
    nextCondition: {
      nextQuestion: "kul01",
      condition: "{{'false' if answer == 'Ja' else 'true'}}",
    },
  }),
  q("Wie oft treiben Sie Sport?", oftSportQuestionType, {
    subQuestionNumber: "a",
  }),
  q("Welchen Sport betreiben Sie?", sportartQuestionType, {
    subQuestionNumber: "b",
  }),
  ...multiRadioQuestions(
    "Welche Erwartungen verbinden Sie mit der Tätigkeit in der Feuerwehr?",
    [
      "Etwas für das Gemeinwohl zu tun.",
      "Anderen Menschen helfen.",
      "Berechtigte eigene Interessen vertreten.",
      "Eigene Probleme selbst in die Hand zu nehmen.",
      "Dass mir die Tätigkeit Spaß macht.",
      "Mit Menschen zusammenkommen, die ich mag.",
      "Eigene Kenntnisse und Erfahrungen zu erweitern.",
      "Etwas zu lernen, was mir für den Beruf etwas nützt.",
      "Eigene Entscheidung und Verantwortung zu übernehmen.",
      "Anerkennung zu finden.",
      "Außergewöhnliche Ereignisse mitzuerleben.",
    ],
    importanceQuestionType,
    { firstId: "kul01" }
  ),
  ...multiRadioQuestions(
    "Wie wichtig ist es Ihnen, dass",
    [
      "alle Einsatzkräfte den Zusammenhalt in der Feuerwehr fördern.",
      "alle Einsatzkräfte Ihre persönliche Würde achten.",
      "alle Einsatzkräfte Ihre persönliche Ehre achten",
      "alle Einsatzkräfte Ihre persönlichen Rechte achten",
      "alle Einsatzkräfte Ihnen in Not und Gefahr beistehen",
      "alle Einsatzkräfte Ihre persönlichen Anschauungen respektieren, selbst wenn es nicht Ihre sind.",
      "die anderen Einsatzkräfte mit Ihnen Zeit verbringen wollen.",
      "die anderen Einsatzkräfte mit Ihnen Spaß haben wollen.",
      "die anderen Einsatzkräfte Sachen erleben wollen.",
      "die anderen Einsatzkräfte Sie als Mensch kennen lernen wollen.",
    ],
    importanceQuestionType
  ),
  ...multiRadioQuestions(
    "Wie wichtig ist es Ihnen, dass",
    [
      "Sie den Zusammenhalt aller Einsatzkräfte fördern.",
      "Sie die Würde aller Einsatzkräfte achten.",
      "Sie die persönliche Ehre aller Einsatzkräfte achten.",
      "Sie die persönlichen Rechte aller Einsatzkräfte achten.",
      "Sie allen Einsatzkräften in Not und Gefahr beistehen",
      "Sie die persönlichen Anschauungen aller Einsatzkräfte respektieren, selbst wenn es nicht Ihre sind.",
      "Sie mit den anderen Einsatzkräften Zeit verbringen wollen.",
      "Sie mit den anderen Einsatzkräfte Spaß haben wollen.",
      "Sie mit den anderen Einsatzkräften Sachen erleben wollen.",
      "Sie die anderen Einsatzkräfte als Mensch kennen lernen wollen.",
    ],
    importanceQuestionType
  ),
  checkboxQuestion(
    "Gibt es in Ihrer Feuerwehr jemanden, der sich speziell um Ihre Belange und Anliegen kümmert?",
    [
      "Wehrführer",
      "Bürgermeister",
      "Leiter der Feuerwehr / Stadt-/Gemeindebrandinspektor",
      "Verwaltungsmitarbeiter/-in",
      "Andere",
      "Feuerwehrförderverein",
      "Weiß nicht",
    ]
  ),
  checkboxQuestion(
    "Worauf Legen Sie Wert, wenn es um die Anerkennung Ihrer Feuerwehr geht?",
    [
      "Ich bin sehr zufrieden mit meiner Tätigkeit, meinen Kameradinnen und Kameraden sowie meiner Ausrüstung und brauche sonst nichts.",
      "Ich wünsche mir, dass der Bürgermeister zur Jahreshauptversammlung erscheint.",
      "Ich wünsche mir, dass wir Feuerwehrangehörige eine Aufwandsentschädigung erhalten.",
      "Ich wünsche mir, dass die Presse  mehr Interesse an unserer Tätigkeit zeigt.",
      "Ich wünsche mir, dass die Bürgerinnen und Bürger  mehr Interesse an unserer Tätigkeit zeigen.",
      "Ich wünsche mir, dass mehr in unserer Feuerwehr gelobt wird.",
      "Ich wünsche mir, dass das Engagement der mir vorgesetzten Führungskräfte mehr wertgeschätzt wird.",
      "Ich wünsche mir, dass bessere Öffentlichkeitsarbeit von der Feuerwehr geleistet wird.",
    ]
  ),
  q(
    "Würden Sie sagen, dass <u>Sie Ihren Vorgesetzten</u> genug Anerkennung zeigen?",
    eherJaQuestionType
  ),
  q(
    "Würden Sie sagen, dass <u>Ihre Vorgesetzten Ihnen</u> genug Anerkennung zeigen?",
    eherJaQuestionType
  ),
  q(
    "Würden Sie sagen, dass <u>Sie Ihren Kameradinnen und Kameraden</u> genug Anerkennung zeigen?",
    eherJaQuestionType
  ),
  q(
    "Würden Sie sagen, dass <u>Ihre Kameradinnen und Kameraden Ihnen</u> genug Anerkennung zeigen?",
    eherJaQuestionType
  ),
  radioQuestion(
    "Erwarten Sie eine Vergütung für Brandsicherheitswachdienste?",
    ["Ja", "Manchmal", "Nein"]
  ),
  radioQuestion(
    "Erhalten Sie persönlich eine gewisse Bezahlung für Ihre Tätigkeiten?",
    [
      "Eine pauschale Aufwandsentschädigung",
      "Ein Honorar",
      "Eine geringfügige zeitbasierte Bezahlung",
      "Nichts davon",
    ]
  ),
  radioQuestion(
    "Können Sie für Ihre finanziellen Auslagen eine Kostenerstattung erhalten?",
    ["Ja", "Nein", "Weiß nicht"],
    {
      nextCondition: {
        condition: "{{'false' if answer == 'Ja' else 'true'}}",
        nextQuestion: "kul11",
      },
    }
  ),
  radioQuestion(
    "Wie häufig machen Sie von dieser Kostenerstattung Gebrauch?",
    [
      "Jedes Mal",
      "In mehr als 75% der Fälle",
      "In etwas der Hälfte der Fälle",
      "Bei etwa jedem vierten Fall",
      "In weniger als 25% der Fälle",
      "Nie",
    ],
    { subQuestionNumber: "a" }
  ),
  checkboxQuestion(
    "Nehmen Sie spezielle Angebote für Feuerwehrleute in Anspruch?",
    [
      "Spezielle Versicherungsangebote",
      "Freibad",
      "Spezielle Handyverträge",
      "Nein",
      "Sonstige",
    ],
    { id: "kul11" }
  ),
  radioQuestion(
    "Haben Sie in Ihrer Feuerwehr ausreichende Möglichkeiten zur Mitsprache?",
    ["Ja", "Zum Teil (ausreichend)", "Zum Teil (unzureichend)", "Nein"]
  ),
  q(
    "Sind Sie mit dem Informationsfluss in Ihrer Feuerwehr zufrieden?",
    eherJaQuestionType
  ),
  radioQuestion("Welche Informationswege bevorzugen Sie?", [
    "E-Mail",
    "WhatsApp",
    "Aushang",
    "Mündliche Mitteilung im Übungsdienst",
  ]),
  radioQuestion(
    "Halten Sie die Art und Weise, wie in der Feuerwehr mit den Dingen umgegangen und Aufgaben gelöst werden, für zeitgemäß?",
    ["Ja, nahezu immer", "Ja, meistens", "Nein, selten", "Nein, nahezu nie"]
  ),
  checkboxQuestion(
    "Wenn Sie gelegentlich mit Ihrer Tätigkeit in der Feuerwehr unzufrieden sind oder sich über etwas in der Feuerwehr ärgern, denken Sie dann daran ...",
    [
      "In eine andere Feuerwehr zu wechseln.",
      "Ganz aus der Feuerwehr auszutreten.",
      "Die Dinge in der Feuerwehr zu ändern.",
      "Alles dabei zu belassen, auch wenn Sie gelegentlich unzufrieden sind.",
    ]
  ),
  radioQuestion(
    "Wie oft kommt es vor, dass Sie sich über die Dinge in der Feuerwehr ärgern?",
    [
      "Nie",
      "Alle paar Monate",
      "Etwa einmal im Monat",
      "Etwa einmal die Woche",
      "Nahezu täglich",
    ],
    {
      nextCondition: {
        nextQuestion: "kul21",
        condition:
          "{{'false' if ['Alle paar Monate','Etwa einmal im Monat','Etwa einmal die Woche','Nahezu täglich'].includes(answer) else 'true' }}",
      },
    }
  ),
  checkboxQuestion(
    "Worüber ärgern Sie sich dann?",
    [
      "Das Verhalten der mir untergebenen Kräfte",
      "Das Verhalten der mir vorgesetzten Kräfte",
      "Das Verhalten der Gruppe an sich",
      "Respektlosigkeit der mir untergebenen Kräfte",
      "Respektlosigkeit der mir vorgesetzten Kräfte",
      "Respektlosigkeit der Gruppe an sich",
      "Bei Entscheidungen übergangen werden",
      "Vor vollendete Tatsachen gestellt werden",
      "Fehlende Anerkennung",
      "Ich fühle mich überfordert.",
      "Ich fühle mich ausgenutzt.",
      "Ich fühle mich nicht ausreichend vorbereitet.",
      "Die Diensteinteilung",
    ],
    { subQuestionNumber: "a" }
  ),
  radioQuestion(
    "Worüber ärgern Sie sich am meisten?",
    [
      "Das Verhalten der mir untergebenen Kräfte",
      "Das Verhalten der mir vorgesetzten Kräfte",
      "Das Verhalten der Gruppe an sich",
      "Respektlosigkeit der mir untergebenen Kräfte",
      "Respektlosigkeit der mir vorgesetzten Kräfte",
      "Respektlosigkeit der Gruppe an sich",
      "Bei Entscheidungen übergangen werden",
      "Vor vollendete Tatsachen gestellt werden",
      "Fehlende Anerkennung",
      "Ich fühle mich überfordert.",
      "Ich fühle mich ausgenutzt.",
      "Ich fühle mich nicht ausreichend vorbereitet.",
      "Die Diensteinteilung",
    ],
    { subQuestionNumber: "b" }
  ),
  checkboxQuestion(
    "Wer könnte diese Ärgernisse maßgeblich abstellen?",
    [
      "Kameraden",
      "Stadtverwaltung",
      "Wehrführer",
      "Externe",
      "Leiter der Feuerwehr (Stadt-/Gemeindebrandinspektor)",
      "Feuerwehrverein",
    ],
    { subQuestionNumber: "c" }
  ),
  radioQuestion(
    "Erwägen Sie, die Einsatzabteilung der Feuerwehr zu verlassen?",
    [
      "Nein, nicht in absehbarer Zeit",
      "Ja, in den nächsten 12 Monaten",
      "Ja, in den nächsten 3 Jahren",
    ],
    { id: "kul21" }
  ),
  radioQuestion("Stichwort Dienstzeitende: Werden Sie ...", [
    "Deutlich vor Erreichen des Dienszeitendes die Feuerwehr verlassen",
    "Mit Erreichen des Dienstzeitendes die Feuerwehr verlassen",
    "Beantragen, die Dienstzeit bis 65 Jahre zu verlängern",
    "Ich weiß es noch nicht.",
  ]),
  ...multiRadioQuestions(
    "Wie ist Ihrer Meinung nach die technische Ausstattung Ihrer Feuerwehr hinsichtlich",
    ["Fahrzeuge", "Geräte", "Persönliche Schutzausstattung"],
    addQuestionType({
      name: "zu viel",
      style: QuestionStyle.Radio,
      options: { values: ["zu viel", "genau richtig", "zu wenig"] },
    })
  ),
  q(
    "Handelt es sich bei Ihrer Tätigkeit um ein Amt, in das man gewählt wird?",
    yesNoQuestionType
  ),
  q("Haben Sie eine Leitungs- oder Führungsfunktion?", yesNoQuestionType, {
    nextCondition: {
      nextQuestion: "fun04",
      condition: "{{'false' if answer == 'Ja' else 'true'}}",
    },
  }),
  radioQuestion(
    "Welche Leitungs- oder Führungsposition haben Sie?",
    [
      "Stadt-/Gemeindebrandinspektor",
      "Stellvertretender Stadt-/Gemeindebrandinspektor",
      "Wehrführer",
      "Stellvertretender Wehrführer",
      "Zugführer",
      "Gruppenführer",
      "Keine davon",
    ],
    { subQuestionNumber: "a" }
  ),
  radioQuestion(
    "Welche sonstige Funktion in der Feuerwehr haben Sie?",
    [
      "Gerätewart",
      "Atemschutzgerätewart",
      "Jugendfeuerwehrwart",
      "Jugendfeuerwehr-Betreuer",
      "Minifeuerwehrwart",
      "Minifeuerwehr-Betreuer",
      "Sicherheitsbeauftragter",
      "Sonstige",
      "Keine",
    ],
    { id: "fun04" }
  ),
  q(
    "Hatten Sie in Ihrer Feuerwehr schon einmal eine „höhere“ Funktion oder mehr Verantwortung als heute?",
    yesNoQuestionType,
    {
      nextCondition: {
        nextQuestion: "fun07",
        condition: "{{'false' if answer == 'Ja' else 'true'}}",
      },
    }
  ),
  q(
    'Was waren die Gründe dafür, dass Sie diese "höhere" Funktion heute nicht mehr haben?',
    reasonsQuestionType,
    { subQuestionNumber: "a" }
  ),
  q(
    "Erwägen Sie, Ihre derzeitige Fach- oder Führungsposition in absehbarer Zeit abzugeben?",
    yesNoNotApplicabaleQuestionType,
    {
      id: "fun07",
      nextCondition: {
        nextQuestion: "fun09",
        condition: "{{'false' if answer == 'Ja' else 'true'}}",
      },
    }
  ),
  q(
    "Was sind die Gründe dafür, dass Sie erwägen, Ihre derzeitige Fach- oder Führungsposition in absehbarer Zeit abzugeben?",
    reasonsQuestionType,
    { subQuestionNumber: "a" }
  ),
  q(
    "Würden Sie gerne mehr Aufgaben in der Feuerwehr übernehmen?",
    yesNoQuestionType,
    { id: "fun09" }
  ),
  q(
    'Würden Sie gerne eine "höhere" Funktion in Ihrer Feuerwehr übernehmen?',
    yesNoQuestionType,
    {
      nextCondition: {
        nextQuestion: "fueh01",
        condition: "{{'false' if answer == 'Ja' else 'true'}}",
      },
    }
  ),
  radioQuestion(
    'Welche "höhere" Funktion würden Sie gerne übernehmen?',
    [
      "Stadtbrandinspektor/Gemeindebrandinspektor",
      "Wehrführer",
      "Zugführer",
      "Gruppenführer",
      "Gerätewart",
      "Atemschutzgerätewart",
      "Jugendfeuerwehrwart",
      "Jugendfeuerwehr-Betreuer",
      "Minifeuerwehrwart",
      "Minifeuerwehr-Betreuer",
      "Andere",
      "Nicht zutreffend",
    ],
    { subQuestionNumber: "a" }
  ),
  checkboxQuestion(
    'Welche Gründe halten Sie bisher davon ab, eine "höhere" Funktion in der Feuerwehr zu übernehmen?',
    [
      " berufliche Gründe",
      "Ich fühlte mich überfordert.",
      "familiäre Gründe",
      "die Tätigkeit war von vornherein zeitlich begrenzt",
      "gesundheitliche Gründe",
      "Spannungen und Schwierigkeiten mit untergebenen Kräften",
      "Umzug",
      "Spannungen und Schwierigkeiten mit vorgesetzten Kräften",
      "Schule, Ausbildung",
      "Spannungen und Schwierigkeiten mit der Gruppe an sich",
      "sonstige",
      "die Gruppe, Organisation wurde aufgelöst.",
      "zeitlicher Aufwand zu groß",
      "es gab keine Finanzierung für das Vorhaben.",
      "finanzieller Aufwand zu groß",
      "das Ziel des Projektes wurde nicht erreicht.",
      "Ich fühlte mich ausgenutzt.",
      "es gab nicht genug Leute, die weiter machen wollten.",
    ],
    { subQuestionNumber: "b" }
  ),
  q(
    'Welche Veränderungen müssen eintreten, damit Sie diese "höhere" Funktion übernehmen würden?',
    longTextQuestionType,
    { subQuestionNumber: "c" }
  ),
  q(
    "Wie zufrieden sind Sie mit Ihrem Leiter der Feuerwehr (Stadt-/Gemeindebrandinspektor)?",
    zufriedenQuestionType,
    { id: "fueh01", subQuestionNumber: "a" }
  ),
  ...multiRadioQuestions(
    "Wie zufrieden sind Sie mit Ihrem Leiter der Feuerwehr (Stadt-/Gemeindebrandinspektor) im Bezug auf ",
    [
      "Führungsverhalten im Einsatz",
      "Führungsverhalten im Übungsdienst",
      "Kameradschaft",
      "Kommunikationsverhalten",
      "Problemlösung",
      "Konfliktlösung",
      "Ausbildungsverhalten",
    ],
    zufriedenQuestionType,
    { subQuestionNumber: "b" }
  ),
  q(
    "Was würden Sie an Stelle Ihres Leiters der Feuerwehr (Stadt-/Gemeindebrandinspektor) anders machen?",
    longTextQuestionType,
    { subQuestionNumber: "c" }
  ),
  q(
    "Wie zufrieden sind Sie mit Ihrem 1. stellvertretenden Leiter der Feuerwehr (1. stv. Stadt-/Gemeindebrandinspektor)?",
    zufriedenQuestionType,
    { subQuestionNumber: "a" }
  ),
  ...multiRadioQuestions(
    "Wie zufrieden sind Sie mit Ihrem 1. stellvertretenden Leiter der Feuerwehr (1. stv. Stadt-/Gemeindebrandinspektor) im Bezug auf ",
    [
      "Führungsverhalten im Einsatz",
      "Führungsverhalten im Übungsdienst",
      "Kameradschaft",
      "Kommunikationsverhalten",
      "Problemlösung",
      "Konfliktlösung",
      "Ausbildungsverhalten",
    ],
    zufriedenQuestionType,
    { subQuestionNumber: "b" }
  ),
  q(
    "Was würden Sie an Stelle Ihres 1. stellvertretenden Leiters der Feuerwehr (1. stv. Stadt-/Gemeindebrandinspektor) anders machen?",
    longTextQuestionType,
    { subQuestionNumber: "c" }
  ),
  q(
    "Gibt es in Ihrer Feuerwehr einen 2. stellvertretenden Leiter der Feuerwehr (2. stv. Stadt-/Gemeindebrandinspektor)?",
    yesNoQuestionType,
    {
      nextCondition: {
        nextQuestion: "fueh11",
        condition: "{{'false' if answer == 'Ja' else 'true'}}",
      },
    }
  ),
  q(
    "Wie zufrieden sind Sie mit Ihrem 2. stellvertretenden Leiter der Feuerwehr (2. stv. Stadt-/Gemeindebrandinspektor)?",
    zufriedenQuestionType,
    { subQuestionNumber: "a" }
  ),
  ...multiRadioQuestions(
    "Wie zufrieden sind Sie mit Ihrem 2. stellvertretenden Leiter der Feuerwehr (2. stv. Stadt-/Gemeindebrandinspektor) im Bezug auf",
    [
      "Führungsverhalten im Einsatz",
      "Führungsverhalten im Übungsdienst",
      "Kameradschaft",
      "Kommunikationsverhalten",
      "Problemlösung",
      "Konfliktlösung",
      "Ausbildungsverhalten",
    ],
    zufriedenQuestionType,
    { subQuestionNumber: "b" }
  ),
  q(
    "Was würden Sie an Stelle Ihres 2. stellvertretenden Leiters der Feuerwehr (2. stv. Stadt-/Gemeindebrandinspektor) anders machen?",
    longTextQuestionType,
    { subQuestionNumber: "c" }
  ),
  q("Wie zufrieden sind Sie mit Ihrem Wehrführer?", zufriedenQuestionType, {
    id: "fueh11",
    subQuestionNumber: "a",
  }),
  ...multiRadioQuestions(
    "Wie zufrieden sind Sie mit Ihrem Wehrführer im Bezug auf",
    [
      "Führungsverhalten im Einsatz",
      "Führungsverhalten im Übungsdienst",
      "Kameradschaft",
      "Kommunikationsverhalten",
      "Problemlösung",
      "Konfliktlösung",
      "Ausbildungsverhalten",
    ],
    zufriedenQuestionType,
    { subQuestionNumber: "b" }
  ),
  q(
    "Was würden Sie an Stelle Ihres Wehrführers anders machen?",
    longTextQuestionType,
    { subQuestionNumber: "c" }
  ),
  q(
    "Wie zufrieden sind Sie mit Ihrem 1. stellvertretenden Wehrführer?",
    zufriedenQuestionType,
    { subQuestionNumber: "a" }
  ),
  ...multiRadioQuestions(
    "Wie zufrieden sind Sie mit Ihrem 1. stellvertretenden Wehrführer im Bezug auf",
    [
      "Führungsverhalten im Einsatz",
      "Führungsverhalten im Übungsdienst",
      "Kameradschaft",
      "Kommunikationsverhalten",
      "Problemlösung",
      "Konfliktlösung",
      "Ausbildungsverhalten",
    ],
    zufriedenQuestionType,
    { subQuestionNumber: "b" }
  ),
  q(
    "Was würden Sie an Stelle Ihres 1. stellvertretenden Wehrführers anders machen?",
    longTextQuestionType,
    { subQuestionNumber: "c" }
  ),
  q(
    "Gibt es in Ihrer Feuerwehr einen 2. stellvertretenden Wehrführer?",
    yesNoQuestionType,
    {
      nextCondition: {
        nextQuestion: "fueh21",
        condition: "{{'false' if answer == 'Ja' else 'true'}}",
      },
    }
  ),
  q(
    "Wie zufrieden sind Sie mit Ihrem 2. stellvertretenden Wehrführer?",
    zufriedenQuestionType,
    { subQuestionNumber: "a" }
  ),
  ...multiRadioQuestions(
    "Wie zufrieden sind Sie mit Ihrem 2. stellvertretenden Wehrführer im Bezug auf",
    [
      "Führungsverhalten im Einsatz",
      "Führungsverhalten im Übungsdienst",
      "Kameradschaft",
      "Kommunikationsverhalten",
      "Problemlösung",
      "Konfliktlösung",
      "Ausbildungsverhalten",
    ],
    zufriedenQuestionType,
    { subQuestionNumber: "b" }
  ),
  q(
    "Was würden Sie an Stelle Ihres 2. stellvertretenden Wehrführers anders machen?",
    longTextQuestionType,
    { subQuestionNumber: "c" }
  ),
  q("Wie zufrieden sind Sie mit Ihren Zugführern?", zufriedenQuestionType, {
    id: "fueh21",
    subQuestionNumber: "a",
  }),
  q(
    "Was würden Sie an Stelle Ihrer Zugführer anders machen?",
    longTextQuestionType,
    { subQuestionNumber: "b" }
  ),
  q("Wie zufrieden sind Sie mit Ihren Gruppenführern?", zufriedenQuestionType, {
    subQuestionNumber: "a",
  }),
  q(
    "Was würden Sie an Stelle Ihrer Gruppenführer anders machen?",
    longTextQuestionType,
    { subQuestionNumber: "b" }
  ),
  radioQuestion(
    "Wie finden Sie die Idee, die Feuerwehrangehörigen mit einem Fragebogen zu befragen?",
    ["Nicht so gut", "Ganz in Ordnung", "Sehr gut"]
  ),
  q("Wollen Sie zusätzlich noch etwas mitteilen?", longTextQuestionType),
];

function multiRadioQuestions(
  text: string,
  subs: string[],
  type: QuestionTypeFragment,
  options?: { firstId?: string; subQuestionNumber?: string }
): QuestionFragment[] {
  return subs.map((sub, idx) => {
    return q(`${text}\t ${sub}`, type, {
      subQuestionNumber: options?.subQuestionNumber ?? "",
      overwriteIncreaseCounter:
        options?.subQuestionNumber !== undefined ? false : idx === 0,
      id: idx === 0 ? options?.firstId : undefined,
    });
  });
}

function radioQuestion(
  text: string,
  values: string[],
  options?: CreateQuestionOptions
): QuestionFragment {
  return q(
    text,
    addQuestionType({
      name: text,
      style: QuestionStyle.Radio,
      options: {
        values,
      },
    }),
    options
  );
}

function checkboxQuestion(
  text: string,
  values: string[],
  options?: CreateQuestionOptions
): QuestionFragment {
  return q(
    text,
    addQuestionType({
      name: text,
      style: QuestionStyle.Radio,
      options: {
        values,
        multiple: true,
      },
    }),
    options
  );
}

export async function createQuestionnaire(name: string) {
  const lid = `${Date.now()}`;

  const createdQuestionTypes: (QuestionTypeFragment & { prevId: string })[] =
    [];
  for (let questionType of questionTypes) {
    const rs = await client.mutate<
      CreateQuestionTypeMutation,
      CreateQuestionTypeMutationVariables
    >({
      mutation: CreateQuestionTypeDocument,
      variables: {
        name: questionType.name,
        style: questionType.style,
        options: questionType.options,
      },
    });
    console.log(rs.data?.createQuestionType.id);
    createdQuestionTypes.push({
      ...rs.data?.createQuestionType!,
      prevId: questionType.id,
    });
  }

  const createdQuestions: (QuestionFragment & { prevId: string })[] = [];
  for (let q of questions) {
    const qt = createdQuestionTypes.find((qt) => qt.prevId === q.type.id);
    if (!qt) {
      console.log(q);
      throw new Error(`QuestionType not found`);
    }
    const rs = await client.mutate<
      CreateQuestionMutation,
      CreateQuestionMutationVariables
    >({
      mutation: CreateQuestionDocument,
      variables: {
        text: q.text,
        required: q.required ?? false,
        typeId: qt.id,
      },
    });
    createdQuestions.push({ ...rs.data?.createQuestion!, prevId: q.id });
  }

  await Promise.all(
    questions
      .filter((q) => !!q.nextCondition)
      .map(async (q) => {
        const cq = createdQuestions.find((cq) => cq.prevId === q.id)!,
          nextCQ = createdQuestions.find(
            (cq) => cq.prevId === q.nextCondition?.nextQuestion
          )!;
        console.log({ cq, nextCQ });
        return await client.mutate<
          UpdateQuestionMutation,
          UpdateQuestionMutationVariables
        >({
          mutation: UpdateQuestionDocument,
          variables: {
            id: cq.id,
            text: q.text,
            required: q.required ?? false,
            typeId: cq.type.id,
            nextCondition: q.nextCondition?.condition,
            nextQuestionId: nextCQ.id,
          },
        });
      })
  );

  const rs = await client.mutate<
    CreateQuestionnaireMutation,
    CreateQuestionnaireMutationVariables
  >({
    mutation: CreateQuestionnaireDocument,
    variables: {
      name: `Lumdataltest ${name} ${lid}`,
      questions: createdQuestions.map((q) => q.id),
    },
  });
  const questionnaire = rs.data?.createQuestionnaire;
  console.log(questionnaire);

  return questionnaire!;
}

export async function createLumdataltest(
  clientName: string,
  stations: { name: string; amount: number }[]
): Promise<GetEventTypeQuery["eventType"]> {
  const questionnaire = await createQuestionnaire(clientName);
  const eventTypeRs = await client.mutate<
    CreateEventTypeMutation,
    CreateEventTypeMutationVariables
  >({
    mutation: CreateEventTypeDocument,
    variables: { name: clientName, questionnaire: questionnaire.id },
  });
  const eventType = eventTypeRs.data?.createEventType!;

  const eventGroupRs = await client.mutate<
    CreateEventGroupMutation,
    CreateEventGroupMutationVariables
  >({
    mutation: CreateEventGroupDocument,
    variables: {
      name: `Lumdataltest ${clientName}`,
      eventTypeId: eventType.id,
    },
  });

  let csv = "Standort,TAN\n";
  for (let station of stations) {
    const rs = await client.mutate<
      CreateEventMutation,
      CreateEventMutationVariables
    >({
      mutation: CreateEventDocument,
      variables: {
        name: `Lumdataltest ${clientName}: ${station.name}`,
        eventGroupId: eventGroupRs.data?.createEventGroup.id!,
        metadata: [{ label: "Standort", value: station.name }],
      },
    });
    const batchRs = await client.mutate<
      GenerateBatchMutation,
      GenerateBatchMutationVariables
    >({
      mutation: GenerateBatchDocument,
      variables: { eventId: rs.data?.createEvent.id!, amount: station.amount },
    });
    const tans = batchRs.data?.generated.accessCodes.map((c) => c.tan)!;
    for (let t of tans) {
      csv += `${station.name},${t}\n`;
    }
    console.log({
      station,
      codes: tans,
    });
  }
  console.log(csv);

  const eventTypeNew = await client.query<
    GetEventTypeQuery,
    GetEventTypeQueryVariables
  >({ query: GetEventTypeDocument, variables: { id: eventType.id } });

  return eventTypeNew.data.eventType!;
}

// @ts-ignore
window.createLumdataltest = createLumdataltest;
