import React from "react";
import { Table as SuiTable } from "semantic-ui-react";
import { useTable, useExpanded, TableOptions } from "react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";
import { TableProps } from "react-table";

export const Table: React.FC<
  TableOptions<any> & {
    renderRowSubComponent: (opts: { row: any }) => React.ReactNode;
  }
> = ({ columns, data, renderRowSubComponent, ...rest }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      ...rest,
    },
    useExpanded
  );

  // Render the UI for your table
  return (
    <SuiTable {...getTableProps()}>
      <SuiTable.Header>
        {headerGroups.map((headerGroup) => (
          <SuiTable.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <SuiTable.HeaderCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </SuiTable.HeaderCell>
            ))}
          </SuiTable.Row>
        ))}
      </SuiTable.Header>
      <SuiTable.Body {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={i}>
              <SuiTable.Row {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <SuiTable.Cell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </SuiTable.Cell>
                  );
                })}
              </SuiTable.Row>
              {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
              {/* @ts-ignore */}
              {row.isExpanded ? (
                <SuiTable.Row>
                  <SuiTable.Cell colSpan={visibleColumns.length}>
                    {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                    {renderRowSubComponent({ row })}
                  </SuiTable.Cell>
                </SuiTable.Row>
              ) : null}
            </React.Fragment>
          );
        })}
      </SuiTable.Body>
    </SuiTable>
  );
};
