import React, { useState } from "react";
import { login } from "../session";
import { useHistory, useLocation } from "react-router-dom";
import logoSrc from "../FeuerwehrAgentur_LOGO.png";
import { LockClosedIcon } from "@heroicons/react/solid";

const LoginForm: React.FC<{ onLogin: () => void }> = ({
  onLogin: handleLogin,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const history = useHistory();
  const location = useLocation<{ from: string }>();

  let { from } = location.state || { from: { pathname: "/" } };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-24 w-auto"
            src={logoSrc}
            alt="FeuerwehrAgentur"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Anmelden
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{" "}
            <a href="#" className="font-medium text-red-600 hover:text-red-500">
              start your 14-day free trial
            </a>
          </p>
        </div>
        <form className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Benutzername
              </label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Passwort
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                setError(undefined);
                login(username, password)
                  .then(() => history.replace(from))
                  .catch((e) => {
                    setError(e.message);
                  })
                  .finally(() => setLoading(false));
              }}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-red-500 group-hover:text-red-400"
                  aria-hidden="true"
                />
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  // return <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
  //     <Grid.Column style={{ maxWidth: 450 }}>
  //         <Image src={logoSrc} />
  //         <Header as="h1" textAlign="center">Anmelden</Header>
  //         <Form size='large'>
  //             <Segment>
  //                 <Form.Input
  //                     fluid
  //                     icon='user'
  //                     iconPosition='left'
  //                     placeholder='Benutzername'
  //                     value={username}
  //                     onChange={e => setUsername(e.target.value)}
  //                 />
  //                 <Form.Input
  //                     fluid
  //                     icon='lock'
  //                     iconPosition='left'
  //                     placeholder='Passwort'
  //                     type='password'
  //                     value={password}
  //                     onChange={e => setPassword(e.target.value)}
  //                 />

  //                 <Button loading={loading} color='red' fluid size='large' onClick={() => {
  //                     setLoading(true);
  //                     setError(null);
  //                     login(username, password)
  //                         .then(() => history.replace(from))
  //                         .catch(e => {
  //                             console.log(e);
  //                             setError(e.message)
  //                         })
  //                         .finally(() => setLoading(false));
  //                 }}>
  //                     Anmelden
  //         </Button>
  //             </Segment>
  //         </Form>
  //         {error && <Message negative>
  //             {error}
  //         </Message>}
  //         <p>
  //             &copy; Feuerwehragentur {new Date().getFullYear()} <a href="https://www.feuerwehragentur.de/impressum">Impressum</a>
  //         </p>
  //     </Grid.Column>
  // </Grid >
};
export default LoginForm;
