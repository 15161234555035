import { ApolloProvider } from "@apollo/client";
import React from "react";
import {
  BrowserRouter as Router, Redirect, Route, RouteProps, Switch
} from "react-router-dom";
import { client } from "./apolloClient";
import { Layout } from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import EventDetail from "./pages/EventDetail";
import EventGroupDetail from "./pages/EventGroupDetail";
import EventTypeDetail from "./pages/EventTypeDetail";
import EventTypeEvaluation from "./pages/EventTypeEvaluation";
import EventTypeList from "./pages/EventTypeList";
import LoginForm from "./pages/Login";
import { isLoggedIn } from "./session";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute: React.FC<React.PropsWithChildren<RouteProps>> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="fwag-bg fixed z-0 bottom-0 w-full h-screen"></div>
      <div className="relative z-10 flex flex-col h-screen justify-between text-gray-800">
        <Router>
          <Switch>
            <Route path="/login">
              <LoginForm onLogin={()=>{}} />
            </Route>
            <PrivateRoute path="/" exact>
              <Layout>
                <Dashboard />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/eventTypes" exact>
              <Layout>
                <EventTypeList />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/eventTypes/:id" exact>
              <Layout>
                <EventTypeDetail />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/eventTypes/:id/evaluation">
              <Layout>
                <EventTypeEvaluation />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/eventTypes/:id/:groupId/:eventId" exact>
              <Layout>
                <EventDetail />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/eventTypes/:id/:groupId" exact>
              <Layout>
                <EventGroupDetail />
              </Layout>
            </PrivateRoute>
            {/* <PrivateRoute path="/questionTypes" exact>
              <Layout>
                <QuestionTypeList />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/questionTypes/:id">
              <Layout>
                <QuestionTypeDetail />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/questionnaires" exact>
              <Layout>
                <QuestionnaireList />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/questionnaires/:id">
              <Layout>
                <QuestionnaireDetail />
              </Layout>
            </PrivateRoute>
            <PrivateRoute path="/raffles">
              <Layout>
                <RaffleList />
              </Layout>
            </PrivateRoute> */}
          </Switch>
        </Router>
      </div>
    </ApolloProvider>
  );
}

export default App;
