import React, { useEffect, useState } from "react";
import { useToken } from "../session";
import { useQuery, gql } from "@apollo/client";
import { Link, useParams, useHistory } from "react-router-dom";
import Table from "../components/Table";
import {
  Header,
  Segment,
  Button,
  Icon,
  Form,
  Input,
  Select,
} from "semantic-ui-react";
import { client } from "../apolloClient";
import { PageContent } from "../components/Layout";

const GET_EVENT_TYPES = gql`
  query EventType($id: ID!) {
    eventType(id: $id) {
      id
      name
      questionnaire {
        id
        name
      }
      eventGroups {
        id
        name
        events {
          id
          name
          statistics {
            sessions
            sessionsClosed
          }
        }
      }
    }
  }
`;

const GET_QUESTIONNAIRES = gql`
  {
    questionnaires {
      id
      name
    }
  }
`;

const CREATE_EVENT_TYPE = gql`
  mutation CreateEventType($name: String!, $questionnaire: ID!) {
    createEventType(name: $name, questionnaire: $questionnaire) {
      id
      name
      questionnaire {
        id
        name
      }
      events {
        id
      }
    }
  }
`;

const CREATE_EVENT = gql`
  mutation CreateEvent(
    $name: String!
    $eventGroupId: ID!
    $metadata: [EventMetadataItemInput!]!
  ) {
    createEvent(name: $name, eventGroupId: $eventGroupId, metadata: $metadata) {
      id
      name
      statistics {
        sessions
        sessionsClosed
      }
    }
  }
`;

const CREATE_EVENT_GROUP = gql`
  mutation CreateEventGroup($name: String!, $eventTypeId: ID!) {
    createEventGroup(name: $name, eventTypeId: $eventTypeId) {
      id
    }
  }
`;

export default function EventTypeDetail() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [name, setName] = useState("");
  const [questionnaire, setQuestionnaire] = useState("");
  const questionnairesQuery = useQuery(GET_QUESTIONNAIRES);
  const [newEventName, setNewEventName] = useState("");

  const [eventType, setEventType] = useState();

  const [questionnairesOptions, setQuestionnairesOptions] = useState([]);
  useEffect(() => {
    if (questionnairesQuery.data && questionnairesQuery.data.questionnaires)
      setQuestionnairesOptions(
        questionnairesQuery.data.questionnaires.map((questionnaire) => ({
          key: questionnaire.id,
          value: questionnaire.id,
          text: questionnaire.name,
        }))
      );
  }, [questionnairesQuery.data]);

  function refetch() {
    setLoading(true);
    client
      .query({ query: GET_EVENT_TYPES, variables: { id } })
      .then(({ data: { eventType } }) => {
        if (!eventType) setError("Veranstaltungstyp nicht gefunden.");
        else {
          setName(eventType.name);
          setQuestionnaire(eventType.questionnaire.id);
          setEventType(eventType);
        }
        setLoading(false);
      })
      .catch((e) => setError(e.message));
  }

  useEffect(() => {
    if (id === "new") {
      setLoading(false);
    } else {
      refetch();
    }
  }, [id]);

  if (id === "new")
    return (
      <PageContent heading="Neuen Veranstaltungstyp erstellen">
        <Segment className="mt-4" loading={loading}>
          <p className="mb-4">
            Vergeben Sie einen Namen für den Veranstaltungstyp (beispielsweise
            "Truppmann 1") und wählen Sie einen Fragebogen aus.
          </p>
          <Form>
            <Form.Group>
              <Input
                style={{ minWidth: "400px" }}
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              <Select
                options={questionnairesOptions}
                placeholder="Fragebogen auswählen"
                value={questionnaire}
                onChange={(_, { value }) => {
                  setQuestionnaire(value);
                }}
              />
            </Form.Group>
          </Form>

          <Button
            positive
            icon
            labelPosition="left"
            onClick={() => {
              client
                .mutate({
                  mutation: CREATE_EVENT_TYPE,
                  variables: { name, questionnaire },
                })
                .then(({ data }) => {
                  history.push("/eventTypes/" + data.createEventType.id);
                });
            }}
          >
            <Icon name="plus" />
            Veranstaltungstyp erstellen
          </Button>
        </Segment>
      </PageContent>
    );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!eventType) return <p>Lade Daten...</p>;

  return (
    <PageContent
      heading={`Veranstaltungstyp${
        eventType ? ": " + eventType.name : " erstellen"
      }`}
    >
      <h2 className="pt-4 mb-4 text-2xl font-bold">Veranstaltungen</h2>
      {eventType.eventGroups.length === 0 && (
        <>
          <p>Bisher wurden keine Veranstaltungen angelegt.</p>
        </>
      )}
      <ul className="space-y-3">
        {eventType.eventGroups.map((group) => (
          <li
            key={group.id}
            className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md"
          >
            <Link to={`/eventTypes/${eventType.id}/${group.id}`}>
              {group.name}{" "}
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                {group.events.length} Unterrichte
              </span>
            </Link>
          </li>
        ))}
      </ul>
      <div className="bg-white px-4 py-6 rounded-md mt-8 shadow">
        <h2 className="text-2xl font-bold">Neue Veranstaltung erstellen</h2>
        <Form>
          <p>
            Vergeben Sie einen Namen für die Veranstaltung. (beispielsweise "
            {eventType.name} {new Date().getMonth() + 1}/
            {new Date().getFullYear()}
            ")
          </p>
          <Form.Group>
            <Input
              style={{ minWidth: "400px" }}
              value={newEventName}
              placeholder="Name"
              onChange={(e) => setNewEventName(e.target.value)}
            />
            <Button
              positive
              icon
              labelPosition="left"
              onClick={() => {
                client
                  .mutate({
                    mutation: CREATE_EVENT_GROUP,
                    variables: {
                      name: newEventName,
                      eventTypeId: eventType.id,
                    },
                  })
                  .then(({ data }) => {
                    history.push(
                      `/eventTypes/${eventType.id}/${data.createEventGroup.id}`
                    );
                  })
                  .catch((err) => console.log(err));

                // eslint-disable-next-line no-restricted-globals
              }}
            >
              <Icon name="plus" />
              Neue Veranstaltung erstellen
            </Button>
          </Form.Group>
        </Form>
      </div>
    </PageContent>
  );
}
